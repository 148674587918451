import React, { ReactNode } from 'react';
import { HeaderContainer } from '../common/Containers/HeaderContainer';
import { ActionIcon, Image } from '@mantine/core';
import Icon from '../common/Icons/Icons/Icon';

interface ParentHeaderProps {
  renderHeading: ReactNode;
  rightContent?: ReactNode;
  sideBarOpened: boolean;
  openSideBar: () => void;
  isMobile: boolean;
}

const ParentHeader = ({ renderHeading, rightContent, sideBarOpened, openSideBar, isMobile }: ParentHeaderProps) => {
  return (
    <header className="h-10 md:h-[70px]">
      <HeaderContainer>
        <div className='w-full flex flex-row items-center justify-between md:justify-start'>
            {!sideBarOpened ? (
              <div className="flex flex-row w-full items-center justify-between">
                <ActionIcon
                  onClick={openSideBar}
                  className="text-gray-600 hover:text-gray-800"
                >
                  <Icon name="menu" />
                </ActionIcon>
                <div className="flex md:hidden flex-row items-center">
                  <Image src={'/landing/logo.png'} alt="logo" className="max-w-20 max-h-20" />
                </div>
                {rightContent ? rightContent : <div className='flex-none md:hidden w-10'/>}
              </div>
            ) : (
              <div className='flex-grow hidden md:flex justify-start'>
                {renderHeading}

            </div>
            )}

          {/* This empty div helps balance the layout on mobile */}

        </div>
      </HeaderContainer>
    </header>
  );
};

export default ParentHeader;