import React, { useState, useRef, useCallback } from 'react';
import { Text, Image, ActionIcon, Modal, SimpleGrid, Button } from '@mantine/core';
import Icon from '../Icons/Icons/Icon';

interface AvatarSelectionProps {
  onChange: (file: File | null) => void;
  error?: string;
}

const AvatarSelection: React.FC<AvatarSelectionProps> = ({ onChange, error }) => {
  const [preview, setPreview] = useState<string | null>(null);
  const [selectedAvatar, setSelectedAvatar] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const avatars = [
    '/avatars/1.png',
    '/avatars/2.png',
    '/avatars/3.png',
    '/avatars/4.png',
    '/avatars/5.png',
    '/avatars/6.png',
    '/avatars/7.png',
    '/avatars/8.png',
  ];

  const handleFileChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result as string);
      };
      reader.onerror = () => {
        console.error('FileReader error:', reader.error);
      };
      reader.readAsDataURL(file);
      onChange(file);
    } else {
      setPreview(null);
      onChange(null);
    }
  }, [onChange]);

  const handleUploadClick = useCallback(() => {
    setIsModalOpen(true);
  }, []);

  const handleAvatarSelect = useCallback(() => {
    setPreview(selectedAvatar);
    setIsModalOpen(false);
    // Convert the selected avatar to a File object
    
    if (selectedAvatar) {
        fetch(selectedAvatar)
          .then(res => res.blob())
          .then(blob => {
        const file = new File([blob], "avatar.png", { type: "image/png" });
        onChange(file);
      });}
  }, [onChange, selectedAvatar]);

  const handleCustomUpload = useCallback(() => {
    fileInputRef.current?.click();
    setIsModalOpen(false);
  }, []);

  const handleDelete = useCallback(() => {
    setPreview(null);
    onChange(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  }, [onChange]);

  return (
    <div className="">
      <div className="w-20 h-20  mx-auto bg-gray-100 rounded-full flex items-center justify-center relative">
        <Image
          src={preview || "/images/user-avatar.png"}
          alt={preview ? "Preview" : "Placeholder"}
          className="border rounded-full overflow-hidden"
          fit="cover"
        />
        <div className="absolute bottom-0 right-0 p-1">
          <ActionIcon 
            radius="xl" 
            className='border border-primary bg-white' 
            onClick={preview ? handleDelete : handleUploadClick}
          >
            <Icon name={preview ? 'trash' : 'pen'} className="w-6 h-6 text-gray-600" />
          </ActionIcon>
        </div>
      </div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept="image/*"
        style={{ display: 'none' }}
      />
      {error && <Text color="red" size="sm" mt={4} align="center">{error}</Text>}

      <Modal size={"xl"} styles={{content:{padding: "20px", borderRadius: "20px",}, title: {fontSize: "20px"}}} opened={isModalOpen} onClose={() => setIsModalOpen(false)} title="Choose an avatar for your learner">
        <SimpleGrid mt={20} cols={3}>
          {avatars.map((avatar, index) => (
            <div className='flex items-center justify-center'>
            <Image
              key={index}
              src={avatar}
              alt={`Avatar ${index + 1}`}
              className={`cursor-pointer border max-w-[100px] max-h-[100px] rounded-full ${selectedAvatar === avatar ? 'border-primary border-2' : 'border-gray-300'} overflow-hidden`}
              onClick={() => setSelectedAvatar(avatar)}
                />
            </div>
          ))}
      
        </SimpleGrid>
        <div className='flex items-center justify-end mt-10'>
            <Button className='bg-primary text-white w-36' onClick={handleAvatarSelect}>Save</Button>
          </div>
      </Modal>
    </div>
  );
};

export default AvatarSelection;