import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ActionIcon, Box, Button, Text, UnstyledButton } from '@mantine/core';
import Icon from '../common/Icons/Icons/Icon';

interface UpgradePlanModalProps {
  isOpen: boolean;
  onClose: () => void;
  onUpgrade: () => void;
  customClasses?: {
    modal?: string;
    title?: string;
    uploadArea?: string;
    previewArea?: string;
    button?: string;
    errorText?: string;
  };
}

const UpgradePlanModal: React.FC<UpgradePlanModalProps> = ({
  isOpen,
  onClose,
  onUpgrade,
  customClasses = {},
}) => {

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);



  if (!isOpen) return null;

  return (
    <div className="absolute w-full md:w-2/3 bottom-full right-0 md:right-10 bg-white border border-gray-300 rounded-xl shadow-md mb-2 z-50">
    <Box p="lg">
        <div className='mb-1 flex flex-row justify-between items-center'><Text className={`text-lg text-gray-800 font-bold ${customClasses.title || ''}`}>Premium Feature</Text><UnstyledButton onClick={onClose}><Icon name='cross' size='1rem' /></UnstyledButton></div>
        <Text className={`text-sm text-gray-600`}>Upgrade your plan to unlock all features.</Text>
        <div className="flex justify-end">
        
          <Button
            onClick={onUpgrade}
            className={`bg-primary hover:bg-blue-950 text-white disabled:bg-gray-300
              disabled:cursor-not-allowed ${customClasses.button || ''}`}
          >
           Upgrade Plan
        </Button>
        </div>
      </Box>
    </div>
  );
};

export default UpgradePlanModal;