
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import useViewportHeight from "src/hooks/useViewPortHeight";
import { BaseSidebar } from "./BaseSidebar";


interface SideBarProps {
  opened: boolean;
}


const ParentProfileSidebar = (props: SideBarProps) => {
  const user = useAppSelector((state) => state.user)
  // useViewportHeight()

  return (
    <BaseSidebar fullName={user.name} screenName="Profile" >
      <></>
    </BaseSidebar>
  );
};

export default ParentProfileSidebar;


//   <UserStatusCard
//   paid={user?.paid || false}
//   handlePayment={handleButtonClick}
//   trialDays={user.trial_days}
// />