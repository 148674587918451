import React, { useState } from 'react';
import { Modal, Select, TextInput, Button, Text, Box, Textarea } from '@mantine/core';

interface FeedbackModalProps {
  isOpen: boolean;
  onClose: () => void;
  feedbackType: string | null;
  setFeedbackType: (value: string | null) => void;
  onSubmit: (feedback: string) => void;
}

const feedbackOptions = [
  { value: 'Inappropriate content', label: 'Inappropriate content' },
  { value: 'Incorrect information', label: 'Incorrect information' },
  { value: 'Guidance not relevant to question asked', label: 'Guidance not relevant to question asked' },
  { value: 'Inadequate support', label: 'Inadequate support' },
  { value: 'Other', label: 'Other' }
];

const FeedbackModal: React.FC<FeedbackModalProps> = ({
  isOpen,
  onClose,
  feedbackType,
  setFeedbackType,
  onSubmit
}) => {
  const [otherFeedback, setOtherFeedback] = useState('');

  const handleSubmit = () => {
    const feedback = feedbackType === 'Other' ? otherFeedback : (feedbackType || '');
    onSubmit(feedback);
    setOtherFeedback('');
  };

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      
      title={<Text className=' text-center '>We appreciate your feedback!</Text>}
      centered
      size="lg"
      styles={(theme) => ({
        content: {
            padding: 10,
            borderRadius: 20
        },
        title: {
            fontSize: 20
        }
      })}
    >
     <Text mb="md">I am flagging this message because...</Text>
      <div className='flex flex-grow flex-col items-end gap-3'>
  
        <Select
          data={feedbackOptions}
          placeholder="Select a reason"
          value={feedbackType}
          w={"100%"}
          onChange={setFeedbackType}
          styles={(theme) => ({
            itemsWrapper: {
                height: 100,
                zIndex: 1000,
            },
            item: {
              '&[data-selected]': {
                '&, &:hover': {
                  backgroundColor: theme.colors.blue[6],
                  color: theme.white,
                },
              },
            },
          })}
        />

      {feedbackType === 'Other' ? (
        <Textarea
          placeholder="Please specify"
          value={otherFeedback}
          className='w-full'
          
          onChange={(event) => setOtherFeedback(event.currentTarget.value)}
          mb="md"
        />
      ): (<div className='h-12'></div>)}
      <Button
        onClick={handleSubmit}
        styles={(theme) => ({
          root: {
            backgroundColor: '#1C2B4B',
            color: 'white',
            '&:hover': {
              backgroundColor: theme.fn.darken('#1C2B4B', 0.1),
            },
          },
        })}
        disabled={!feedbackType || (feedbackType === 'Other' && !otherFeedback)}
      >
        Submit
      </Button>
      </div>
    </Modal>
  );
};

export default FeedbackModal;