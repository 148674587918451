import React from 'react';
import { Card, Text, UnstyledButton } from '@mantine/core';
import ChildCard from 'src/components/Cards/ChildCard';
import { ChildrenData } from 'src/interfaces/Children';
import Icon from '../../Icons/Icons/Icon';

interface ChildCardListProps {
  children: ChildrenData[];
  onAddChild: () => void;
  onDeleteChild: (name: string) => void;
  onPress: (child: ChildrenData) => void;
  onEditChild: (child: ChildrenData) => void;
}

const ChildCardList: React.FC<ChildCardListProps> = ({ children, onAddChild, onDeleteChild, onPress, onEditChild }) => {
  return (
    <div className="flex flex-col max-w-7xl h-56">
           <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4 overflow-y-auto p-2 max-h-full">
          {children.map((child) => (
            <div
              key={child.id}
              className="flex h-24 items-center flex-row justify-between w-full shadow-md px-4 py-2 rounded-xl cursor-pointer"
              // onClick={() => onPress(child)}
            >
              <div className="flex items-center flex-row">
                <div className="w-14 h-14 bg-gray-200 border border-gray-300 rounded-full mr-4">
                  <img 
                    src={child.profile_picture_url || "/images/user-avatar.png"} 
                    alt={`${child.username}`} 
                    className="w-full h-full rounded-full object-cover" 
                  />
                </div>
                <div>
                  <Text size="xl" weight={700}>{child.username}</Text>
                  <Text className='text-gray-500 text-md'>Science, {child.level}</Text>
                  <UnstyledButton 
                    className='text-sm text-red-500 underline'
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteChild(child.username);
                    }}
                  >
                    Delete Learner
                  </UnstyledButton>
                </div>
              </div>
              <div>
                <UnstyledButton onClick={(e) => {
                  e.stopPropagation();
                  onEditChild(child);
                }}>
                  <Icon name='edit' size='30' color='gray' />
                </UnstyledButton>
              </div>
            </div>
          ))}
          <div onClick={onAddChild} className="flex gap-4 flex-row cursor-pointer items-center w-full h-24 shadow-md px-4 py-2 rounded-xl">
            <div className="w-14 h-14 rounded-full flex items-center justify-center">
              <Icon name='addUser' size='40' color="#29748F" />
            </div>
            <Text className='text-primary font-semibold text-lg'>Add Another Learner</Text>
          </div>
        </div>
    </div>
  );
};

export default ChildCardList;