import { Card, Image, Text } from "@mantine/core"
import Icon from "src/components/common/Icons/Icons/Icon"

interface EmptyChatComponentProps {
    username: string;
    sendMessage: (message: string, isFileUploaded: boolean) => void;
}
export const EmptyChatComponent = ({username, sendMessage}: EmptyChatComponentProps) => {
    return(<div className="flex justify-center h-[100%] items-center">
    <div className="flex flex-col items-center justify-center">
    <Image src={'/icons/favicon.png'} mah={80}  maw={80} className="self-center"/>
    <Text className="text-primary font-bold text-xxl">Hi {username ? username : 'there'}!</Text>

    <div className="search-prompt-step flex flex-col gap-4 my-8  w-full lg:w-[500px]">
      <div onClick={() => {sendMessage('Can you teach me about a science topic?', false)}} className="flex items-center justify-center px-10 py-3 bg-white border border-gray-200 hover:border-gray-400 rounded-3xl cursor-pointer">
        <Text className=" text-md font-semibold text-gray-400">Can you teach me about a science topic?</Text>
      </div>
      <div onClick={() => {sendMessage('Answer my question', false)}} className="flex items-center justify-center px-10 py-3 bg-white border border-gray-200 hover:border-gray-400 rounded-3xl cursor-pointer ">
        <Text className="text-md font-semibold text-gray-400">Answer my question</Text>
      </div>
    </div>
    </div>
    </div>)
}