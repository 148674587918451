import { ChildLevel } from "src/enums/Child";
import * as Yup from "yup";


const currentYear = new Date().getFullYear();

export const SCHOOLS = [
    { value: 'Admiralty Primary School', label: 'Admiralty Primary School' },
    { value: 'Ahmad Ibrahim Primary School', label: 'Ahmad Ibrahim Primary School' },
    { value: 'Ai Tong School', label: 'Ai Tong School' },
    { value: 'Alexandra Primary School', label: 'Alexandra Primary School' },
    { value: 'Anchor Green Primary School', label: 'Anchor Green Primary School' },
    { value: 'Anderson Primary School', label: 'Anderson Primary School' },
    { value: 'Ang Mo Kio Primary School', label: 'Ang Mo Kio Primary School' },
    { value: 'Anglo-Chinese School (Junior)', label: 'Anglo-Chinese School (Junior)' },
    { value: 'Anglo-Chinese School (Primary)', label: 'Anglo-Chinese School (Primary)' },
    { value: 'Angsana Primary School', label: 'Angsana Primary School' },
    { value: 'Balestier Hill Primary School', label: 'Balestier Hill Primary School' },
    { value: 'Beacon Primary School', label: 'Beacon Primary School' },
    { value: 'Bedok Green Primary School', label: 'Bedok Green Primary School' },
    { value: 'Bendemeer Primary School', label: 'Bendemeer Primary School' },
    { value: 'Blangah Rise Primary School', label: 'Blangah Rise Primary School' },
    { value: 'Boon Lay Garden Primary School', label: 'Boon Lay Garden Primary School' },
    { value: 'Bukit Panjang Primary School', label: 'Bukit Panjang Primary School' },
    { value: 'Bukit Timah Primary School', label: 'Bukit Timah Primary School' },
    { value: 'Bukit View Primary School', label: 'Bukit View Primary School' },
    { value: 'Canberra Primary School', label: 'Canberra Primary School' },
    { value: 'Canossa Convent Primary School', label: 'Canossa Convent Primary School' },
    { value: 'Cantonment Primary School', label: 'Cantonment Primary School' },
    { value: 'Casuarina Primary School', label: 'Casuarina Primary School' },
    { value: 'Catholic High', label: 'Catholic High' },
    { value: 'Cedar Primary School', label: 'Cedar Primary School' },
    { value: 'Changkat Primary School', label: 'Changkat Primary School' },
    { value: 'CHIJ (Katong) Primary School', label: 'CHIJ (Katong) Primary School' },
    { value: 'CHIJ (Kellock)', label: 'CHIJ (Kellock)' },
    { value: 'CHIJ (Our Lady of Good Counsel)', label: 'CHIJ (Our Lady of Good Counsel)' },
    { value: 'CHIJ (Our Lady of the Nativity)', label: 'CHIJ (Our Lady of the Nativity)' },
    { value: 'CHIJ (Our Lady Queen of Peace)', label: 'CHIJ (Our Lady Queen of Peace)' },
    { value: 'CHIJ Primary (Toa Payoh)', label: 'CHIJ Primary (Toa Payoh)' },
    { value: "CHIJ St Nicholas Girls' (Primary)", label: "CHIJ St Nicholas Girls' (Primary)" },
    { value: 'Chongfu Primary School', label: 'Chongfu Primary School' },
    { value: 'Chongzheng Primary School', label: 'Chongzheng Primary School' },
    { value: 'Chua Chu Kang Primary School', label: 'Chua Chu Kang Primary School' },
    { value: 'Clementi Primary School', label: 'Clementi Primary School' },
    { value: 'Compassvale Primary School', label: 'Compassvale Primary School' },
    { value: 'Concord Primary School', label: 'Concord Primary School' },
    { value: 'Coral Primary School', label: 'Coral Primary School' },
    { value: 'Corporation Primary School', label: 'Corporation Primary School' },
    { value: 'Da Qiao Primary School', label: 'Da Qiao Primary School' },
    { value: 'Damai Primary School', label: 'Damai Primary School' },
    { value: 'Dazhong Primary School', label: 'Dazhong Primary School' },
    { value: 'De La Salle School', label: 'De La Salle School' },
    { value: 'East Coast Primary School', label: 'East Coast Primary School' },
    { value: 'East Spring Primary School', label: 'East Spring Primary School' },
    { value: 'East View Primary School', label: 'East View Primary School' },
    { value: 'Edgefield Primary School', label: 'Edgefield Primary School' },
    { value: 'Elias Park Primary School', label: 'Elias Park Primary School' },
    { value: 'Endeavour Primary School', label: 'Endeavour Primary School' },
    { value: 'Eunos Primary School', label: 'Eunos Primary School' },
    { value: 'Evergreen Primary School', label: 'Evergreen Primary School' },
    { value: 'Fairfield Methodist School (Primary)', label: 'Fairfield Methodist School (Primary)' },
    { value: 'Farrer Park Primary School', label: 'Farrer Park Primary School' },
    { value: 'Fengshan Primary School', label: 'Fengshan Primary School' },
    { value: 'Fernvale Primary School', label: 'Fernvale Primary School' },
    { value: 'First Toa Payoh Primary School', label: 'First Toa Payoh Primary School' },
    { value: 'Frontier Primary School', label: 'Frontier Primary School' },
    { value: 'Fuchun Primary School', label: 'Fuchun Primary School' },
    { value: 'Fuhua Primary School', label: 'Fuhua Primary School' },
    { value: 'Gan Eng Seng Primary School', label: 'Gan Eng Seng Primary School' },
    { value: 'Geylang Methodist School (Primary)', label: 'Geylang Methodist School (Primary)' },
    { value: 'Gongshang Primary School', label: 'Gongshang Primary School' },
    { value: 'Greendale Primary School', label: 'Greendale Primary School' },
    { value: 'Greenridge Primary School', label: 'Greenridge Primary School' },
    { value: 'Guangyang Primary School', label: 'Guangyang Primary School' },
    { value: "Haig Girls' School", label: "Haig Girls' School" },
    { value: 'Henry Park Primary School', label: 'Henry Park Primary School' },
    { value: "Holy Innocents' High School", label: "Holy Innocents' High School" },
    { value: 'Hong Wen School', label: 'Hong Wen School' },
    { value: 'Horizon Primary School', label: 'Horizon Primary School' },
    { value: 'Hougang Primary School', label: 'Hougang Primary School' },
    { value: 'Huamin Primary School', label: 'Huamin Primary School' },
    { value: 'Innova Primary School', label: 'Innova Primary School' },
    { value: 'Jiemin Primary School', label: 'Jiemin Primary School' },
    { value: 'Jing Shan Primary School', label: 'Jing Shan Primary School' },
    { value: 'Junyuan Primary School', label: 'Junyuan Primary School' },
    { value: 'Jurong Primary School', label: 'Jurong Primary School' },
    { value: 'Jurong West Primary School', label: 'Jurong West Primary School' },
    { value: 'Juying Primary School', label: 'Juying Primary School' },
    { value: 'Keming Primary School', label: 'Keming Primary School' },
    { value: 'Kheng Cheng School', label: 'Kheng Cheng School' },
    { value: 'Kong Hwa School', label: 'Kong Hwa School' },
    { value: 'Kranji Primary School', label: 'Kranji Primary School' },
    { value: 'Kuo Chuan Presbyterian Primary School', label: 'Kuo Chuan Presbyterian Primary School' },
    { value: 'Lakeside Primary School', label: 'Lakeside Primary School' },
    { value: 'Lianhua Primary School', label: 'Lianhua Primary School' },
    { value: 'Loyang Primary School', label: 'Loyang Primary School' },
    { value: 'MacPherson Primary School', label: 'MacPherson Primary School' },
    { value: 'Maha Bodhi School', label: 'Maha Bodhi School' },
    { value: 'Maris Stella High School (Primary)', label: 'Maris Stella High School (Primary)' },
    { value: 'Marsiling Primary School', label: 'Marsiling Primary School' },
    { value: 'Marymount Convent School', label: 'Marymount Convent School' },
    { value: 'Mayflower Primary School', label: 'Mayflower Primary School' },
    { value: 'Mee Toh School', label: 'Mee Toh School' },
    { value: 'Meridian Primary School', label: 'Meridian Primary School' },
    { value: "Methodist Girls' School (Primary)", label: "Methodist Girls' School (Primary)" },
    { value: 'Montfort Junior School', label: 'Montfort Junior School' },
    { value: 'Nan Chiau Primary School', label: 'Nan Chiau Primary School' },
    { value: 'Nan Hua Primary School', label: 'Nan Hua Primary School' },
    { value: 'Nanyang Primary School', label: 'Nanyang Primary School' },
    { value: 'Naval Base Primary School', label: 'Naval Base Primary School' },
    { value: 'New Town Primary School', label: 'New Town Primary School' },
    { value: 'Ngee Ann Primary School', label: 'Ngee Ann Primary School' },
    { value: 'North Spring Primary School', label: 'North Spring Primary School' },
    { value: 'North View Primary School', label: 'North View Primary School' },
    { value: 'North Vista Primary School', label: 'North Vista Primary School' },
    { value: 'Northland Primary School', label: 'Northland Primary School' },
    { value: 'Northoaks Primary School', label: 'Northoaks Primary School' },
    { value: 'Oasis Primary School', label: 'Oasis Primary School' },
    { value: 'Opera Estate Primary School', label: 'Opera Estate Primary School' },
    { value: 'Palm View Primary School', label: 'Palm View Primary School' },
    { value: 'Park View Primary School', label: 'Park View Primary School' },
    { value: 'Pasir Ris Primary School', label: 'Pasir Ris Primary School' },
    { value: "Paya Lebar Methodist Girls' School (Primary)", label: "Paya Lebar Methodist Girls' School (Primary)" },
    { value: 'Pei Chun Public School', label: 'Pei Chun Public School' },
    { value: 'Pei Hwa Presbyterian Primary School', label: 'Pei Hwa Presbyterian Primary School' },
    { value: 'Pei Tong Primary School', label: 'Pei Tong Primary School' },
    { value: 'Peiying Primary School', label: 'Peiying Primary School' },
    { value: 'Pioneer Primary School', label: 'Pioneer Primary School' },
    { value: 'Poi Ching School', label: 'Poi Ching School' },
    { value: 'Princess Elizabeth Primary School', label: 'Princess Elizabeth Primary School' },
    { value: 'Punggol Cove Primary School', label: 'Punggol Cove Primary School' },
    { value: 'Punggol Green Primary School', label: 'Punggol Green Primary School' },
    { value: 'Punggol Primary School', label: 'Punggol Primary School' },
    { value: 'Punggol View Primary School', label: 'Punggol View Primary School' },
    { value: 'Qifa Primary School', label: 'Qifa Primary School' },
    { value: 'Qihua Primary School', label: 'Qihua Primary School' },
    { value: 'Queenstown Primary School', label: 'Queenstown Primary School' },
    { value: 'Radin Mas Primary School', label: 'Radin Mas Primary School' },
    { value: 'Raffles Girls\' Primary School', label: 'Raffles Girls\' Primary School' },
    { value: 'Red Swastika School', label: 'Red Swastika School' },
    { value: 'River Valley Primary School', label: 'River Valley Primary School' },
    { value: 'Riverside Primary School', label: 'Riverside Primary School' },
    { value: 'Rivervale Primary School', label: 'Rivervale Primary School' },
    { value: 'Rosyth School', label: 'Rosyth School' },
    { value: 'Rulang Primary School', label: 'Rulang Primary School' },
    { value: "Singapore Chinese Girls' (Primary)", label: "Singapore Chinese Girls' (Primary)" },
    { value: 'Sembawang Primary School', label: 'Sembawang Primary School' },
    { value: 'Seng Kang Primary School', label: 'Seng Kang Primary School' },
    { value: 'Sengkang Green Primary School', label: 'Sengkang Green Primary School' },
    { value: 'Shuqun Primary School', label: 'Shuqun Primary School' },
    { value: 'Si Ling Primary School', label: 'Si Ling Primary School' },
    { value: 'South View Primary School', label: 'South View Primary School' },
    { value: 'Springdale Primary School', label: 'Springdale Primary School' },
    { value: "St Hilda's Primary School", label: "St Hilda's Primary School" },
    { value: "St Andrew's Junior School", label: "St Andrew's Junior School" },
    { value: "St Anthony's Canossian School (Primary)", label: "St Anthony's Canossian School (Primary)" },
    { value: "St Anthony's Primary School", label: "St Anthony's Primary School" },
    { value: "St Gabriel's Primary School", label: "St Gabriel's Primary School" },
    { value: "St Margaret's Primary School", label: "St Margaret's Primary School" },
    { value: "St Stephen's School", label: "St Stephen's School" },
    { value: "St Joseph's Institution Junior", label: "St Joseph's Institution Junior" },
    { value: 'Stamford Primary School', label: 'Stamford Primary School' },
    { value: 'Tampines North Primary School', label: 'Tampines North Primary School' },
    { value: 'Tampines Primary School', label: 'Tampines Primary School' },
    { value: 'Tanjong Katong Primary School', label: 'Tanjong Katong Primary School' },
    { value: 'Tao Nan School', label: 'Tao Nan School' },
    { value: 'Teck Ghee Primary School', label: 'Teck Ghee Primary School' },
    { value: 'Teck Whye Primary School', label: 'Teck Whye Primary School' },
    { value: 'Telok Kurau Primary School', label: 'Telok Kurau Primary School' },
    { value: 'Temasek Primary School', label: 'Temasek Primary School' },
    { value: 'Townsville Primary School', label: 'Townsville Primary School' },
    { value: 'Unity Primary School', label: 'Unity Primary School' },
    { value: 'Waterway Primary School', label: 'Waterway Primary School' },
    { value: 'Wellington Primary School', label: 'Wellington Primary School' },
    { value: 'West Grove Primary School', label: 'West Grove Primary School' },
    { value: 'West Spring Primary School', label: 'West Spring Primary School' },
    { value: 'West View Primary School', label: 'West View Primary School' },
    { value: 'Westwood Primary School', label: 'Westwood Primary School' },
    { value: 'White Sands Primary School', label: 'White Sands Primary School' },
    { value: 'Woodgrove Primary School', label: 'Woodgrove Primary School' },
    { value: 'Woodlands Primary School', label: 'Woodlands Primary School' },
    { value: 'Woodlands Ring Primary School', label: 'Woodlands Ring Primary School' },
    { value: 'Xinghua Primary School', label: 'Xinghua Primary School' },
    { value: 'Xingnan Primary School', label: 'Xingnan Primary School' },
    { value: 'Xinmin Primary School', label: 'Xinmin Primary School' },
    { value: 'Xishan Primary School', label: 'Xishan Primary School' },
    { value: 'Yangzheng Primary School', label: 'Yangzheng Primary School' },
    { value: 'Yew Tee Primary School', label: 'Yew Tee Primary School' },
    { value: 'Yio Chu Kang Primary School', label: 'Yio Chu Kang Primary School' },
    { value: 'Yishun Primary School', label: 'Yishun Primary School' },
    { value: 'Yu Neng Primary School', label: 'Yu Neng Primary School' },
    { value: 'Yuhua Primary School', label: 'Yuhua Primary School' },
    { value: 'Yumin Primary School', label: 'Yumin Primary School' },
    { value: 'Zhangde Primary School', label: 'Zhangde Primary School' },
    { value: 'Zhenghua Primary School', label: 'Zhenghua Primary School' },
    { value: 'Zhonghua Primary School', label: 'Zhonghua Primary School' },
];
  
export const LEVELS = [
    { value: ChildLevel.primary_3, label: ChildLevel.primary_3 },
    { value: ChildLevel.primary_4, label: ChildLevel.primary_4 },
    { value: ChildLevel.primary_5, label: ChildLevel.primary_5 },
    { value: ChildLevel.primary_6, label: ChildLevel.primary_6 },
  ];

export const registerChildSchema = Yup.object().shape({
    image: Yup.mixed()
      .nullable()
      .test('fileSize', 'The file is too large', (value) => {
        if (!value || !(value instanceof File)) return true;
        return value.size <= 5000000; // 5MB max size
      })
      .test('fileType', 'Unsupported file format', (value) => {
        if (!value || !(value instanceof File)) return true;
        return ['image/jpeg', 'image/png', 'image/gif'].includes(value.type);
      }),
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    birthYear: Yup.number()
      .required("Birth year is required")
      .min(currentYear - 100, "Invalid birth year")
      .max(currentYear, "Invalid birth year"),
    grade: Yup.string()
      .required("Grade is required"),
    school: Yup.string().required("School is required"),
    username: Yup.string()
      .required("Username is required")
      .matches(/^\S*$/, "Username should not contain spaces"),
    password: Yup.string()
      .required("Password is required")
      .matches(/^(?=.*[A-Z])(?=.*\d).{8,}$/, "Use at least one capital letter and a number in your password"),
  });

export const childUpdateSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    level: Yup.string().required("Level is required"),
    school: Yup.string().required("School is required"),
    password: Yup.string()
      .test('password-validation', 'Use at least one capital letter and a number in your password', function(value) {
        if (!value) return true; // Allow empty string
        return /^(?=.*[A-Z])(?=.*\d).{8,}$/.test(value);
      })
});