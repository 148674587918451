import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthLayout } from 'src/components/Layout/AuthLayout';
import { VisualSideLayout } from 'src/components/Layout/VisualsLayout';
import { SignUpForm } from 'src/components/Forms/Signup';
import { authService } from "src/services/services/auth/auth.api";
import { ResponseKind } from "src/services/services/MainService";
import { ErrorKind } from "src/services/services/MainService/apiProblem";
import { authRoutes } from "src/navigators/routes/auth";
import { sendSuccessNotification } from 'src/utils/notifications';
import { NotificationTypes } from 'src/enums/Notifications';
import useResponsive from 'src/hooks/useResponsive';
import { Notifications } from '@mantine/notifications';
import { Alert } from '@mantine/core';
import FeedbackModal from 'src/components/Modals/FeedbackModal';
import { ErrorMessage } from 'src/components/common/ErrorMessage/ErrorMessage';
import { ConfirmFeedbackModal } from 'src/components/Modals/ConfirmationModal';
import { FeedbackMessage } from 'src/components/common/FeedbackMessage/FeedbackMessage';

const SignUp: React.FC = () => {
  const navigation = useNavigate()
  const { isMobile } = useResponsive()
  const [loading, setLoading] = useState(false);
  const [feedback, setFeedback] = useState({ message: '', title: '' })
  const [error, setError] = useState('');

  const navigateToSignin = useCallback(() => {
    navigation("/auth/login");
  }, []);

  useEffect(() => {
    if (feedback.message) {
      const handleLogin = () => {
        setFeedback({ message: '', title: '' })
        navigateToSignin()
      }
      setTimeout(handleLogin, 2000)
    }
    return
  }, [feedback])

  const signUp = async (values: any) => {
    setLoading(true)
    try {
      const response = await authService.signUp(
        values.firstName + " " + values.lastName,
        values.email,
        values.password,
        values.phone
      );

      if (response.kind == ResponseKind.OK) {
        setError('')
        const data = response.data;

        if (data?.is_active) {
        } else {
          setFeedback({ message: "We have sent an account activation email, please verify your account", title: "User created successfully" })
        }
      } else if (response.kind == ErrorKind.REJECTION_ERROR) {
        setError(response?.errors);
        throw response.errors
      }
    } catch (e) {

    } finally {
      setLoading(false)
    }
  };

  const dismissError = () => {
    setError('')
  }

  return (
    <AuthLayout
      visualComponent={<VisualSideLayout />}
      formComponent={
        <div>
          <FeedbackMessage feedback={feedback ? feedback.message : ""} title={feedback.title} handleClose={dismissError} />
          <ErrorMessage error={error ? error : ""} handleClose={dismissError} />
          <Notifications
            position={isMobile ? "top-center" : "bottom-right"}
            limit={isMobile ? 1 : 5}
          />
          <SignUpForm
            handleSignin={navigateToSignin}
            onSubmit={signUp}
            loading={loading}
            error={error}
          />
        </div>

      }
    />
  );
};

export default SignUp;





