import { Image, Text } from "@mantine/core";
import ReactJoyride, { CallBackProps, STATUS, Step } from "react-joyride"

interface ChildWalkthroughProps {
    startWalkthrough?: boolean
    onCloseWalkthrough: () => void
}
export const ChildWalkthrough = ({ startWalkthrough, onCloseWalkthrough }: ChildWalkthroughProps) => {
    const steps: Step[] = [
        {
          target: 'body',
          content: <div className="flex flex-col p-2 gap-2">
            <Image className="rounded-xl mb-4" src="/images/chatIntefracePlaceholder.png" alt="doodles" />
            <Text className="text-xl font-semibold">Welcome to MYAi</Text>
            <Text>Before you start, we recommend having a look at
            our product tour to get familiar with the main features.</Text>
          </div>,
          disableBeacon: true,
          placement: 'center'
        },
        {
          target: '.recent-chat-step',
          content: <div className="flex flex-col items-start justify-start gap-2">
          <Text className="text-lg font-semibold">Recent Chats</Text>
          <Text className="text-left">Here are all your recent chats.</Text>
          </div>,
        },
        {
          target: '.new-chat-step',
          content: <div className="flex flex-col items-start justify-start gap-2">
          <Text className="text-lg font-semibold">Start a new chat</Text>
          <Text className="text-left">Start a new chat with just a click.</Text>
          </div>,
          },
            {
          target: '.upload-files-step',
          content: <div className="flex flex-col items-start justify-start gap-2">
          <Text className="text-lg font-semibold">Upload Files</Text>
          <Text className="text-left">You can upload files to the chat.</Text>
          </div>,
          },
    
        {
          target: '.search-prompt-step',
          content: <div className="flex flex-col items-start justify-start gap-2">
          <Text className="text-lg font-semibold">Search Prompts</Text>
          <Text className="text-left">Get started with your chat without typing.</Text>
          </div>,
        },
    
      ];

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { action, index, origin, status, type } = data;
    
        if (status === STATUS.FINISHED || status === STATUS.SKIPPED) {
          onCloseWalkthrough()
        }
      };

    return (
        <ReactJoyride
        steps={steps}
        run={startWalkthrough}
        continuous
        showProgress
        scrollToFirstStep
        showSkipButton
        hideBackButton
        hideCloseButton
        disableOverlayClose
        callback={handleJoyrideCallback}
        styles={{
          tooltip: {
            borderRadius: '20px',
            width: '400px'
          },
         tooltipContainer: {
      
       
         },
         options: {
            arrowColor: '#FFFFFF',
            primaryColor: '#29748F',
         },
         buttonNext: {
          backgroundColor: '#29748F',
          borderRadius: '10px',
          padding: '10px 20px'
         },
         buttonSkip: {
          border: '1px solid #29748F',
          padding: '10px 20px',
          borderRadius: '10px',
         }
        }}
      />
    )
}