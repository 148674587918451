import { Text } from '@mantine/core';
import React from 'react';

interface UserTypeToggleProps {
  userType: 'Learner' | 'Parent';
  onToggle: (type: 'Learner' | 'Parent') => void;
}

export const UserTypeToggle: React.FC<UserTypeToggleProps> = ({ userType, onToggle }) => {
  return (
    <div className="flex rounded-full bg-gray-200 p-1">
      <button
        className={`flex-1  py-1 sm:py-3 px-4 rounded-full bg-gray-200 border-0 font-semibold transition-colors ${
          userType === 'Learner' ? 'bg-primary' : ''
        }`}
        onClick={() => onToggle('Learner')}
      >
        <Text className={`${userType === 'Learner' ? 'text-white' : 'text-gray-700'} font-semibold`}>Learner</Text>
      </button>
      <button
        className={`flex-1 py-1 sm:py-3 px-4 rounded-full bg-gray-200 font-semibold border-0 transition-colors ${
          userType === 'Parent' ? 'bg-primary text-white' : 'text-gray-700'
        }`}
        onClick={() => onToggle('Parent')}
      >
        <Text className={`${userType === 'Parent' ? 'text-white' : 'text-gray-700'} font-semibold`}>Parent</Text>
      </button>
    </div>
  );
};