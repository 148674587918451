import { ActionIcon, Image, Loader, useMantineTheme } from "@mantine/core";
import { useClipboard } from '@mantine/hooks';
import { showNotification } from '@mantine/notifications';
import { useCallback, useEffect, useRef, useState } from "react";
import ReactMarkdown from 'react-markdown';
import Icon from "../common/Icons/Icons/Icon";
import { isValidJSON } from "src/utils/helpers";

interface BotMessage {
  message: string
  index: number
  isLatest?: boolean
  streaming?: boolean
  traceless?: boolean
  toggleOptions?: () => void
  closeOptions?: () => void
  showOptions?: boolean
}

const BotMessage = (props: BotMessage) => {
  const { streaming, isLatest, traceless, toggleOptions = () => { }, closeOptions = () => { }, showOptions } = props
  const theme = useMantineTheme()
  const [latest, setLatest] = useState(isLatest)
  const lastMessageRef = useRef<HTMLDivElement | null>(null)
  const clipboard = useClipboard({ timeout: 500 });

  useEffect(() => {
    setLatest(isLatest)
  }, [isLatest])

  const handleCopy = (textToCopy: string) => {
    clipboard.copy(textToCopy);
    showNotification({
      message: 'Text copied to clipboard',
      color: "grape",
    });
  };

  if (streaming && (!props?.message || props.message.length === 0)) {
    return (
      <div className="flex items-center space-x-4">
        <Image src={'/icons/favicon.png'} mah={40} maw={40} className="mt-2" />
    <div className="inline-block text-center bg-white border border-gray-200 rounded-tr-lg rounded-b-lg p-3">

      <Loader size={"md"} variant="dots" color="#29748F" />
    </div>
    
    </div>
    )
  }

  if (isValidJSON(props.message)) {
    const formattedMessage = JSON.parse(props.message)
    return (
      <div className="flex items-start space-x-4">
        <Image src={'/icons/favicon.png'} mah={40} maw={40} className="self-start mt-2" />
        <div className="flex-1">
          <div className="rounded-lg bg-white border border-gray-200 w-full md:max-w-[500px] p-4">
            <div className="text-sm text-black">
              <ReactMarkdown>{formattedMessage.text}</ReactMarkdown>
              {formattedMessage.image_url && (
                <div className="mt-3 border border-gray-200 rounded-lg p-2">
                  <Image 
                    src={formattedMessage.image_url} 
                    radius="sm" 
                    className="w-full"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="w-full">
      <div className="flex flex-row items-end justify-between gap-4">
        <div className=" flex flex-row gap-4">
          <Image src={'/icons/favicon.png'} mah={40} maw={40} className="self-center" />
          <div className={`flex flex-col items-start justify-start`}>
            <div className={`text-md leading-[22px] text-left inline-block  border-1/2  rounded-lg bg-white px-8 py-3`}>
              <ReactMarkdown>{props.message}</ReactMarkdown>
            </div>
          </div>
        </div>
        <ActionIcon className="" size={"1rem"} onClick={() => handleCopy(props.message)}><Icon name="copy" size="1rem" /></ActionIcon>
      </div>
    </div>

  );
};


export default BotMessage
