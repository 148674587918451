import React from 'react';
import { ActionIcon, Text, ThemeIcon } from "@mantine/core";
import Icon from "../../common/Icons/Icons/Icon";
import { ChatSession } from "src/interfaces/Chat";
import { ChatBubbleOvalLeftIcon } from '@heroicons/react/24/outline';

interface ReadOnlyChatHistoryTileProps {
  item: ChatSession;
  onPress: (id: string) => void;
  selected: boolean;
}

export const ReadOnlyChatHistoryTile: React.FC<ReadOnlyChatHistoryTileProps> = ({
  item,
  onPress,
  selected
}) => {
  const handleClick = (e: React.MouseEvent) => {
    e.stopPropagation(); // Prevent the click from bubbling up to the parent
    onPress(item.id);
  };

  return (
    <div className={`flex flex-row items-center justify-between p-2 my-1 rounded ${selected ? 'bg-gray-200' : 'hover:bg-gray-100'}`}>

    <div 
      className="flex items-center max-w-[80%] cursor-pointer"
      onClick={handleClick}
    >
      <div className="h-5 w-5 mr-2">
        <ChatBubbleOvalLeftIcon className="h-5 w-5" />

      </div>
      <span className="text-sm truncate">{item.title}</span>
    </div>
  </div>
  );
};