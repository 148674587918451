import Config from "src/config";
import { api, Api } from "../MainService/api";
import { ErrorKind } from "../MainService/apiProblem";
import { clearRole, clearToken, getToken, setToken } from "src/utils/storage";
import { UserToken } from "src/interfaces";
import { authService } from "../auth/auth.api";
import { ResponseKind } from "../MainService";
import { Prices, UserPlan } from "src/interfaces/Payments";

const PAYMENTS_URL = Config.PAYMENTS_URL;

export class PaymentApi {
  private api: Api;

  constructor(api: Api) {
    this.api = api;
  }

  async getPrices() {
    try {
      const response = await this.api.apisauce.get<Prices>(
        `${PAYMENTS_URL}/prices`
      );

      let data = await this.api.getResponse(response, true);
      return data;
    } catch (e) {
      console.log(e);
      return { kind: ErrorKind.BAD_DATA_ERROR };
    }
  }

  async getCurrentPlan() {
    try {
      const response = await this.api.apisauce.get<UserPlan>(
        `${PAYMENTS_URL}/current-plan`
      );

      let data = await this.api.getResponse(response, true);
      return data;
    } catch (e) {
      console.log(e);
      return { kind: ErrorKind.BAD_DATA_ERROR };
    }
  }


  async createPortalSession() {
    try {
      const response = await this.api.apisauce.post<string>(
        `${PAYMENTS_URL}/create-portal-session`,
         undefined
      );

      let data = await this.api.getResponse(response, true);
      return data;
    } catch (e) {
      console.log(e);
      return { kind: ErrorKind.BAD_DATA_ERROR };
    }
  }

  async createPaymentSession(priceId: string) {
    try {
      const response = await this.api.apisauce.get<{url: string}>(
        `${PAYMENTS_URL}/create-payment-session?priceId=${priceId}`,
         undefined
      );

      let data = await this.api.getResponse(response, true);
      return data;
    } catch (e) {
      console.log(e);
      return { kind: ErrorKind.BAD_DATA_ERROR };
    }
  }
}

export const paymentService = new PaymentApi(api);
