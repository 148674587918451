import { useState, useCallback } from 'react';
import { useNotifications } from 'src/hooks/useNotifications';
import { documentService } from 'src/services/services/documents/documents.api';
import { NotificationTypes, PubSubNotificationTypes } from 'src/enums/Notifications';
import { sendFailureNotification } from 'src/utils/notifications';

export const useFileUpload = () => {
    const [uploadStatus, setUploadStatus] = useState<'idle' | 'uploading' | 'success' | 'error'>('idle');
    const [uploadedFile, setUploadedFile] = useState<File | null>(null);
    const client = useNotifications()?.client;

    const uploadFile = useCallback(async (file: File, sessionId: string): Promise<boolean> => {
      setUploadStatus('uploading');
      setUploadedFile(file);
      try {
        const res = await documentService.uploadDoc(file, sessionId);
        if (!res) {
          throw new Error("Error uploading file");
        }
        return new Promise((resolve, reject) => {
          const handleServerMessage = (data: any) => {
            if (data.message.data) {
              const message = JSON.parse(data.message.data.toString());
              
              if (message.event_type === PubSubNotificationTypes.fileUpload && message.file_name === file.name) {
                console.log("response from pub sub", message);
                
                if (message.level === "Info") {
                  if (message.status === "Completed") {
                    client?.off("server-message", handleServerMessage);
                    setUploadStatus('success');
                    resolve(true);
                  }} else {
                    client?.off("server-message", handleServerMessage);
                    setUploadStatus('error');
                    sendFailureNotification(NotificationTypes.fileUpload, `File upload failed: ${message.status}`);
                    reject(new Error(message.status));
                  
                }
              }
            }
          };
          client?.on("server-message", handleServerMessage);
        });
      } catch (error) {
        console.error('Error uploading file:', error);
        setUploadStatus('error');
        throw error; // Re-throw the error instead of returning false
      }
    }, [client]);

    return { uploadStatus, uploadedFile, uploadFile, setUploadedFile, client, setUploadStatus };
};