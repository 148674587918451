// // AccountView.tsx
// import React, { useState } from 'react';
// import { Card, Text, Button } from '@mantine/core';
// import ChangePasswordModal from '../Modals/ChangePasswordModal';

// interface AccountViewProps {
//   user: {
//     firstName: string;
//     lastName: string;
//     email: string;
//     username: string;
//     avatar: string;
//   };
// }

// export const AccountView: React.FC<AccountViewProps> = ({ user }) => {
//   const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);

//   return (
//     <div className='my-4 w-1/2'>
//       <div className="flex items-center mb-6">
//         <div className="w-16 h-16 bg-gray-200 rounded-full mr-4">
//           {user.avatar ? (
//             <img src={user.avatar} alt={`${user.firstName} ${user.lastName}`} className="w-full h-full rounded-full object-cover" />
//           ) : (
//             <div className="w-full h-full flex items-center justify-center text-2xl font-bold text-gray-400">
//               {user.firstName.charAt(0).toUpperCase()}
//             </div>
//           )}
//         </div>
//         <div>
//           <Text size="xl" weight={700}>{`${user.firstName} ${user.lastName}`}</Text>
//           <Text size="sm" color="dimmed">{user.username}</Text>
//         </div>
//       </div>

//       <div className="space-y-4">
//         <div>
//           <Text size="sm" weight={500} className="mb-1">Name</Text>
//           <div className="flex space-x-2">
//             <Text className="flex-1 p-2 bg-gray-100 rounded">{user.firstName}</Text>
//             <Text className="flex-1 p-2 bg-gray-100 rounded">{user.lastName}</Text>
//           </div>
//         </div>
//         <div>
//           <Text size="sm" weight={500} className="mb-1">Email Address</Text>
//           <Text className="p-2 bg-gray-100 rounded">{user.email}</Text>
//         </div>
//         <div>
//           <Text size="sm" weight={500} className="mb-1">Plan</Text>
//           <Text className="p-2 bg-gray-100 rounded">{user.username}</Text>
//         </div>
//         <div>
//           <Text size="sm" weight={500} className="mb-1">Password</Text>
//           <div className="flex items-center justify-between">
//             <Text className="p-2 bg-gray-100 rounded flex-grow mr-2">••••••••</Text>
//             <Button variant="outline" onClick={() => setIsPasswordModalOpen(true)}>
//               Change Password
//             </Button>
//           </div>
//         </div>
//       </div>

//       <ChangePasswordModal
//         isOpen={isPasswordModalOpen}
//         onClose={() => setIsPasswordModalOpen(false)}
//       />
//     </div>
//   );
// };

import React, { useState, useCallback } from 'react';
import { Card, Text, Button, Modal, UnstyledButton } from '@mantine/core';
import ChangePasswordModal from '../Modals/ChangePasswordModal';
import useChildrenData from "src/hooks/useChildrenData";
import ChildList from "src/components/common/Lists/ChildList";
import { EditChildForm } from 'src/components/Forms/Child/EditChildForm';
import { ChildUpdationData, ChildrenData } from 'src/interfaces/Children';
import { useNavigate } from "react-router-dom";
import { parentRoutes } from "src/navigators/routes/ParentRoutes";
import useResponsive from "src/hooks/useResponsive";
import { UpdateChildModal } from '../Modals/UpdateChildModal';
import { User } from 'src/interfaces';
import Icon from '../common/Icons/Icons/Icon';
import ChildCardList from 'src/components/common/Lists/ChildList';
import { ConfirmFeedbackModal } from '../Modals/ConfirmationModal';
import { usePayments } from 'src/hooks/usePayment';

interface AccountViewProps {
  user: User
}

export const AccountView: React.FC<AccountViewProps> = ({ user }) => {
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const { children, deleteChild, updateChild } = useChildrenData();
  const [visible, setVisible] = useState(false)
  const [deleteChildId, setDeleteChildId] = useState<string>('')
  const [selectedChild, setSelectedChild] = useState<ChildrenData | null>(null);
  const navigate = useNavigate();
  const {userPlan} = usePayments()
  const { isMobile } = useResponsive();

  const onAddChild = useCallback(() => {
    navigate(parentRoutes.REGISTER_CHILD);
  }, [navigate]);

  const onPressChild = useCallback((child: ChildrenData) => {
    setSelectedChild(child);
  }, []);

  const onCloseModal = useCallback(() => {
    setSelectedChild(null);
  }, []);

  const onUpdateChild = useCallback((updatedChildData: Partial<ChildUpdationData>) => {
    if (selectedChild) {
      updateChild(selectedChild.username, updatedChildData);
      onCloseModal();
    }
  }, [selectedChild, updateChild]);

  const onDeleteChild = useCallback((id: string) => {
    setDeleteChildId(id)
    setVisible(true)
  }, [])

  return (
    <div className='my-4 h-full flex flex-col justify-between w-full px-0 md:px-2'>
      <ConfirmFeedbackModal
        title="Delete Child"
        feedback="Are you sure you want to delete this child?"
        confirmLabel="Delete Child"
        visible={visible}
        onClose={() => setVisible(false)}
        onConfirm={() => deleteChild(deleteChildId)}
      />
      <div className='flex flex-col gap-4'>
      <Text className="text-lg font-medium text-gray-500">My Profile</Text>
      <div className="flex items-center h-24 flex-row justify-between w-full md:w-96 mb-10 shadow-md px-4 py-2 rounded-xl">
        <div className="flex items-center flex-row">
        <div className="w-14 h-14 bg-gray-200 border border-gray-300 rounded-full mr-4">
          <img src={user.profile_picture_url ? user.profile_picture_url : "/images/user-avatar.png"} alt={`${user.name}`} className="w-full h-full rounded-full object-cover" />
        </div>
        <div>
          <Text size="xl" weight={700}>{`${user.name}`}</Text>
          {/* <UnstyledButton className='text-sm text-blue-500 underline' onClick={() => setIsPasswordModalOpen(true)}>
            Change Password
          </UnstyledButton> */}
          {/* <Text size="sm" color="dimmed">{user.email}</Text> */}
        </div>
        </div>
        <div>
         {/* <UnstyledButton>
          <Icon name='edit' size='30' color='gray' />
         </UnstyledButton> */}
        </div>
      </div>
      <div className='flex flex-col gap-4'>
      <Text className="text-lg font-medium text-gray-500">Registered Learners</Text>
      <ChildCardList
        onPress={onPressChild}
        onAddChild={onAddChild}
        onEditChild={onPressChild}
        children={children}
        onDeleteChild={onDeleteChild}
      />
      </div>
      </div>
  

      {children.length > 0 && !userPlan?.has_active_subscription && (
        <div className='flex mt-8 flex-col md:flex-row items-center justify-between bg-primary px-2 md:px-10 py-2 md:py-4 rounded-3xl gap-1'>
          <div className='flex flex-col items-center md:items-start gap-1'>
          <Text className='text-sm md:text-lg font-semibold text-white' >Want to add new learners?</Text>
          <Text className='text-xxs md:text-xs text-center md:text-left text-white' >Upgrade your plan to add new learners to your account.</Text>
          </div>
          <div className='flex flex-col gap-2'> 
          <UnstyledButton onClick={() => {navigate(parentRoutes.PAYMENT)}} variant="filled" color="blue" className="mt-2 font-semibold bg-white rounded-2xl px-6 py-2">
            Upgrade Your Plan
          </UnstyledButton>

          </div>
        </div>
      )}

      <ChangePasswordModal
        isOpen={isPasswordModalOpen}
        onClose={() => setIsPasswordModalOpen(false)}
      />

      <UpdateChildModal
        isOpen={!!selectedChild}
        onClose={onCloseModal}
        selectedChild={selectedChild}
        onUpdateChild={onUpdateChild}
      />

    </div>
  );
};