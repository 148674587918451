import { Image, Text } from "@mantine/core"

interface AuthHeader{
    title: string
    subtitle: string
}

export const AuthHeader = (props: AuthHeader) => {
    const {title, subtitle} = props
    
    return(
        <div className='flex flex-row justify-between items-center'>
        <div className='flex flex-col'>
            <Text className="text-xxl font-extrabold text-gray-800">{title}</Text>
            <Text className="text-gray-600 hidden lg:block">{subtitle}</Text>
            </div>
            <div className='w-40 lg:hidden'>
                <Image src="/logo.png" />
            </div>
        </div>
    )
}