import { Text } from "@mantine/core"
import { CustomButton } from "src/components/common/Buttons/CustomButton"
import { ChatSession } from "src/interfaces/Chat"
import ChatHistoryTile from "../HistoryTile"

interface ChatSessionList {
    onNewSession: () => void
    groupedChats: Record<string, ChatSession[]>
    onSelectSession: (chatSession: ChatSession) => void
    selectedSession: string
    hideNewChatButton?: boolean
    onDeleteSession: (id: string) => void
}

export const ChatSessionsList = (props: ChatSessionList) => {
    const { onNewSession, groupedChats, selectedSession, onSelectSession, onDeleteSession, hideNewChatButton } = props
    return (
        <>
            {!hideNewChatButton && <CustomButton
                onClick={onNewSession}
                className="text-base font-semibold"
                leftIcon="edit"
                variant="default"
                size="default"
            >
                New Chat
            </CustomButton>}

            <Text className={`recent-chat-step font-semibold text-base mb-3 ${hideNewChatButton ? 'mt-0' : 'mt-5'}`} >Recent Chats</Text>

            <div className="overflow-y-auto no-scrollbar flex-grow">
                {Object.entries(groupedChats).map(([group, groupChats]) => (
                    <div key={group} className="mb-4">
                        <Text className="text-base font-medium text-gray-500 mb-4">{group}</Text>
                        {groupChats.map((chat) => (
                            <ChatHistoryTile
                                key={chat.id}
                                item={chat}
                                selected={chat.id === selectedSession}
                                onPress={onSelectSession}
                                onPressDelete={onDeleteSession}
                            />
                        ))}
                    </div>
                ))}
            </div>
        </>
    )
}