import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { showNotification } from "@mantine/notifications";
import { NotificationTypes } from "src/enums/Notifications";
import { ChatMessage, FlaggedData } from "src/interfaces/Chat";
import { ChildCreationData, ChildUpdationData } from "src/interfaces/Children";
import { ResponseKind } from "src/services/services/MainService";
import { parentService } from "src/services/services/parent/parent.api";
import { sendFailureNotification, sendSuccessNotification } from "src/utils/notifications";
import {
  setChildren,
  addChild,
  updateChild as updateChildAction,
  removeChild,
  setChildMessages,
  setChildSessions,
  setSelectedSession,
  setChatLoading,
  setSelectedChild,
} from "src/redux/redux-store/children/childrenReducer"; // Adjust the import path as needed
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";

const useChildrenData = () => {
  const dispatch = useAppDispatch();
  const {
    children,
    childMessages,
    childSessions,
    selectedSession,
    chatLoading,
    selectedChild,
  } = useAppSelector((state) => state.child);
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getChildren();
  }, []);

  useEffect(() => {
    if (selectedChild) {
      getChildSessions(selectedChild);
    } else {
      dispatch(setChildSessions([]));
      dispatch(setSelectedSession(""));
      dispatch(setChildMessages([]));
    }
  }, [selectedChild]);

  const getChildren = async () => {
    const response = await parentService.getChildren();
    console.log("get children response", response)
    if (response.kind === ResponseKind.OK && response.data) {
      dispatch(setChildren(response.data.children));
    }
  };

  const createChild = async (data: ChildCreationData, profile_pic: File | null) => {
    try{
      setLoading(true)
      const response = await parentService.createChild(data, profile_pic);
    
      if (response.kind === ResponseKind.OK && response.data) {
        dispatch(addChild(response.data))
        // getChildren();
        showNotification({
          message: "Child created successfully",
          color: "green",
        });
        return true;
      } else if (response.kind === "rejected") {
        sendFailureNotification(NotificationTypes.childCreation, response.errors);
        return false;
      }
    }catch(e){

    }finally{
      setLoading(false)
    }
  };

  const setChildPreference = async (preference: number) => {
    const response = await parentService.setChildPreference(preference);
    
    if (response.kind === ResponseKind.OK && response.data) {
      // getChildren();
      showNotification({
        message: "Preference set successfully",
        color: "green",
      });
      return true;
    } else if (response.kind === "rejected") {
      sendFailureNotification(NotificationTypes.childPreference, response.errors);
      return false;
    }
  };

  const deleteChild = async (username: string) => {
    const response = await parentService.deleteChild(username);

    if (response.kind === ResponseKind.OK && response.data) {
      dispatch(removeChild(username));
      showNotification({
        message: "Child deleted successfully",
        color: "green",
      });
    } else {
      showNotification({
        message: "Something went wrong",
        color: "red",
      });
    }
  };

  const flagMessage = async (flagMessage: FlaggedData) => {
    const response = await parentService.flagMessage(flagMessage);
    if (response.kind === ResponseKind.OK) {
      sendSuccessNotification(
        NotificationTypes.flagMessage,
        "Your complaint was registered, you will hear shortly from our team!"
      );
    } else {
      sendFailureNotification(
        NotificationTypes.flagMessage,
        "Your complaint wasn't registered, please try again later!"
      );
    }
  };

  const getChildSessions = async (username: string) => {
    const response = await parentService.getChildSessions(username);

    if (response.kind === ResponseKind.OK && response.data) {
      dispatch(setChildSessions(response.data.chat_sessions));
    } else {
      showNotification({
        message: "Something went wrong",
        color: "red",
      });
    }
  };

  const updateChild = async (id: string, childData: Partial<ChildUpdationData>) => {
    const response = await parentService.updateChild(id, childData);

    if (response.kind === ResponseKind.OK && response.data) {
      dispatch(updateChildAction({ id, data: childData }));
      getChildren();
      showNotification({
        message: "Child updated successfully",
        color: "green",
      });
      return true;
    } else if (response.kind === "rejected") {
      sendFailureNotification(NotificationTypes.childUpdation, response.errors);
      return false;
    }
  };

  const onSelectSession = (id: string) => {
    dispatch(setSelectedSession(id));
  };

  const getChildMessages = async (username: string, sessionId: string) => {
    const response = await parentService.getChildChat(username, sessionId);

    if (response.kind === ResponseKind.OK && response.data) {
      const tempMessages: ChatMessage[] = [
        { content: "Hi! I am Tutorbot", role: "ai", created_at: "" },
      ];
      dispatch(setChildMessages([...tempMessages, ...response.data.messages]));
    } else {
      showNotification({
        message: "Something went wrong",
        color: "red",
      });
    }
  };

  return {
    children,
    createChild,
    selectedSession,
    deleteChild,
    getChildMessages,
    onSelectSession,
    childSessions,
    childMessages,
    selectedChild,
    loading,
    updateChild,
    setChildPreference,
    setSelectedChild: (childId: string) => dispatch(setSelectedChild(childId)),
    flagMessage,
  };
};

export default useChildrenData;