import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import PaymentFeedback from 'src/screens/payment/Feedback';
import ParentDashboard from 'src/screens/parent/Dashboard';
import RegisterChildScreen from 'src/screens/parent/RegisterChild';
import ChildView from 'src/screens/parent/ChildView';
import LearnerPortalScreen from 'src/screens/parent/LearnerPortal';
import ProfileScreen from 'src/screens/parent/Profile';
import PricingScreen from 'src/screens/parent/PricingPlans';
import PrepareLearnersScreen from 'src/screens/parent/PrepareLearner';
import RegisterChildOnboardingScreen from 'src/screens/parent/RegisterChildOnboarding';

export const parentRoutes = {
    DASHBOARD: `/parent/dashboard`,
    PREPARE_LEARNERS: `/parent/prepareLearners`,
    REGISTER_CHILD: `/parent/registerChild`,
    REGISTER_CHILD_ONBOARDING: `/parent/registerChild/onboarding`,
    LEARNER_PORTAL: `/parent/learnerPortal`,
    PROFILE: `/parent/profile`,
    PAYMENT: `/parent/payment`,
    CHILD_VIEW: `/parent/childView`,
    PAYMENT_FEEDBACK: `/parent/payment/feedback`,
};

const ParentRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate replace to={parentRoutes.DASHBOARD} />} />
      <Route path={parentRoutes.DASHBOARD.replace('/parent', '')} element={<ParentDashboard />} />
      <Route path={parentRoutes.REGISTER_CHILD_ONBOARDING.replace('/parent', '')} element={<RegisterChildOnboardingScreen />} />
      <Route path={parentRoutes.PREPARE_LEARNERS.replace('/parent', '')} element={<PrepareLearnersScreen />} />
      <Route path={parentRoutes.REGISTER_CHILD.replace('/parent', '')} element={<RegisterChildScreen />} />
      <Route path={parentRoutes.LEARNER_PORTAL.replace('/parent', '')} element={<LearnerPortalScreen />} />
      <Route path={parentRoutes.PROFILE.replace('/parent', '')} element={<ProfileScreen />} />
      <Route path={parentRoutes.CHILD_VIEW.replace('/parent', '')} element={<ChildView />} />
      <Route path={parentRoutes.PAYMENT.replace('/parent', '')} element={<PricingScreen />} />
      <Route path={parentRoutes.PAYMENT_FEEDBACK.replace('/parent', '')} element={<PaymentFeedback />} />
      <Route path="*" element={<Navigate replace to={parentRoutes.DASHBOARD.replace('/parent', '')} />} />
    </Routes>
  );
};

export default ParentRoutes;