import {
  Burger,
  Group,
  Header,
  MediaQuery,
  Text,
  createStyles,
  useMantineTheme,
  useMantineColorScheme,
  Image,
  ActionIcon,
  UnstyledButton,
} from "@mantine/core";

import { FunctionComponent, useCallback, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { UserRole } from "src/enums/User";
import { useAuth } from "src/hooks/useAuth";
import { authRoutes } from "src/navigators/routes/auth";
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import { ResponseKind } from "src/services/services/MainService";
import { authService } from "src/services/services/auth/auth.api";
import { chatService } from "src/services/services/chat/chat.api";
import { clearToken } from "src/utils/storage";
import { StyledContainer } from "./common/StyledComponents/Container";
import { COLORS, FONT_SIZES } from "src/utils/styles";
import {
  NavBarContainer,
  NavBarParentContainer,
} from "./common/StyledComponents/NavBarContainer";
import { confirmDeletionModal } from "./Modals/ConfirmationModal";
import { IconButton } from "./common/Buttons/IconButton";
import Icon from "./common/Icons/Icons/Icon";
import useResponsive from "src/hooks/useResponsive";
import { FocusButton } from "./common/Buttons/FocusButton";
import { sendFailureNotification } from "src/utils/notifications";
import { NotificationTypes } from "src/enums/Notifications";
import { ChatBubbleOvalLeftIcon } from "@heroicons/react/24/outline";
import { useChat } from "src/hooks/useChat";

const useStyles = createStyles((theme) => ({
  header: {
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[6] : COLORS.bg,

    // Or directly specifying a breakpoint
    "@media (min-width: 768px)": {
      border: 0,
      // Adjustments for devices wider than 768px
    },
  },
}));

enum ButtonKeys {
  dashboard = 0,
  clearChat = 1,
  darkMode = 2,
  deleteAccount = 3,
}
interface TopNavBarProps {
  opened: boolean;
  handleOpen?: () => void;
}

const TopNavbar = (props: TopNavBarProps) => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { classes } = useStyles();
  const dispatch = useAppDispatch();
  const { opened, handleOpen } = props;
  const streaming = useAppSelector((state) => state.chat.streaming);
  const selectedSession = useAppSelector((state) => state.chat.selectedSession);
  const {newSession} = useChat()
  const user = useAppSelector((state) => state.user);
  const theme = useMantineTheme();

  const { toggleColorScheme } = useMantineColorScheme();
  const { isMobile, backgroundColor, backgroundColorExterior } =
    useResponsive();



  return isMobile ? (
    <Header className={classes.header} height={"40"}>
      <NavBarParentContainer
        theme={{ backgroundColor: backgroundColorExterior }}
      >
        <NavBarContainer theme={{ backgroundColor: backgroundColor }}>
          <Group w={"100%"} position="apart">
            <MediaQuery largerThan="md" styles={{ display: "none" }}>
              <Burger
                color={COLORS.icon}
                size={"1rem"}
                opened={true}
                onClick={handleOpen}
              ></Burger>
            </MediaQuery>
            <div className="flex flex-row items-center">
              <Image src={'/landing/logo.png'} alt="logo" className="max-w-20 max-h-20" />
              </div>
            <div>
            <UnstyledButton
              className="bg-secondary w-6 h-6 rounded-md p-[5px]"
              onClick={newSession}
            >
              <img src="/icons/newChat.png" alt="edit" />
            </UnstyledButton>
            </div>
          </Group>
        </NavBarContainer>
      </NavBarParentContainer>
    </Header>
  ) : (
    <Header className={classes.header} height={"70"}>
      <NavBarParentContainer
        theme={{ backgroundColor: backgroundColorExterior }}
      >
        <NavBarContainer theme={{ backgroundColor: backgroundColor }}>
        <Group position="apart" w={"100%"}>
              <div className="flex flex-row items-center justify-center">
              <ChatBubbleOvalLeftIcon className="mr-2 size-6" />
                <span className="text-base font-semibold">{selectedSession.title}</span>
              </div>

            </Group>
        </NavBarContainer>
      </NavBarParentContainer>
    </Header>
  );
};

export default TopNavbar;
