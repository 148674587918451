import React, { ReactNode } from 'react';
import { HeaderContainer } from '../common/Containers/HeaderContainer';

interface SimpleHeaderProps {
  renderHeading: ReactNode;
}

const SimpleHeader = ({ renderHeading }: SimpleHeaderProps) => {
  return (
    <header className="h-10 md:h-[70px]">
      <HeaderContainer>
        <div className='w-full flex flex-row items-center justify-center'>
          <div className='flex-grow flex justify-center lg:justify-start'>
            <div className='lg:ml-4'>
              {renderHeading}
            </div>
          </div>
        </div>
      </HeaderContainer>
    </header>
  );
};

export default SimpleHeader;