import React from 'react';
import { Textarea, ActionIcon, UnstyledButton } from '@mantine/core';
import { ArrowForward } from '@mui/icons-material';
import { FileTile } from 'src/components/Chat/FileTile';
import FileUploadModal from 'src/components/Modals/FileUpload';
import Icon from '../Icons/Icons/Icon';
import { COLORS } from 'src/utils/styles';
import UpgradePlanModal from 'src/components/Modals/UpgradePlanModal';

interface ChatInputProps {
  inputMessage: string;
  setInputMessage: (message: string) => void;
  onSend: (message: string) => void;
  uploadedFile: File | null;
  setUploadedFile: React.Dispatch<React.SetStateAction<File | null>>;
  uploadStatus: 'idle' | 'uploading' | 'success' | 'error';
  streaming: boolean;
  handleUpgrade: () => void;
  access: "Free" | "Premium";
  disabled: boolean;
}

const ChatInput: React.FC<ChatInputProps> = ({
  inputMessage,
  setInputMessage,
  onSend,
  uploadedFile,
  setUploadedFile,
  handleUpgrade,
  access,
  uploadStatus,
  streaming,
  disabled
}) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [upgradeModalOpen, setUpgradeModalOpen] = React.useState(false);

  const handleSend = () => {
    if (!disabled) {
      onSend(inputMessage);
    }
  };

  return (
    <div className="mb-2 relative">
      {uploadedFile && uploadStatus == 'idle' && (
        <FileTile file={uploadedFile} onRemove={() => setUploadedFile(null)} />
      )}
      <div className="flex items-center flex-row gap-2">
        <Textarea
          value={inputMessage}
          autosize
          size="sm"
          w="100%"
          minRows={1}
          maxRows={2}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              if (!disabled) {
                 handleSend();
              }
            }
          }}
          styles={{
            input: {
              '&:focus': {
                borderColor: COLORS.primary,
              },
              paddingRight: '3rem',
            },
            rightSection: {
              marginRight: 10,
            },
          }}
          radius={10}
          onChange={(e) => setInputMessage(e.currentTarget.value)}
          placeholder="Ask your question"
          disabled={streaming || uploadStatus === 'uploading'}
          rightSection={
            <ActionIcon className='upload-files-step' variant="light" onClick={() => {
              if (access === "Free") {
                setUpgradeModalOpen(true);
              } else {
                setIsModalOpen(true);
              }
            }}>
              <Icon name="attach" size="1rem" color="black" />
            </ActionIcon>
          }
        />
        <UnstyledButton
          className="bg-primary disabled:bg-gray-300 w-8 h-8 rounded-md p-[5px]"
          disabled={disabled || isModalOpen}
          onClick={handleSend}
        >
          <img  src="/icons/send.png" />
        </UnstyledButton>
      </div>
      <UpgradePlanModal
        isOpen={upgradeModalOpen}
        onClose={() => setUpgradeModalOpen(false)}
        onUpgrade={handleUpgrade}
      />
      <FileUploadModal
        isOpen={isModalOpen}
        customClasses={{
          modal: 'bg-white',
          title: 'text-xl text-gray-800',
          button: 'bg-blue-500 hover:bg-blue-600 text-white',
          errorText: 'text-red-600 font-bold',
        }}
        onClose={() => setIsModalOpen(false)}
        onFileUpload={(file) => setUploadedFile(file)}
        allowedFileTypes="image/*,application/pdf"
        maxFileSize={10 * 1024 * 1024}
      />
    </div>
  );
};

export default ChatInput;