import React, { useState } from 'react';
import { Text, Select, Button, Textarea, Image, Stack, UnstyledButton } from '@mantine/core';
import SimpleLayout from 'src/components/Layout/SimpleLayout';
import { StyledContainer } from "src/components/common/StyledComponents/Container";
import { StyledContainerInset } from "src/components/common/StyledComponents/BackgroundContainer";
import useResponsive from "src/hooks/useResponsive";
import { CustomButton } from 'src/components/common/Buttons/CustomButton';
import Icon from 'src/components/common/Icons/Icons/Icon';
import { useAppSelector } from 'src/redux/redux-store/hooks';
import { useNavigate } from 'react-router-dom';
import { parentRoutes } from 'src/navigators/routes/ParentRoutes';
import useChildrenData from 'src/hooks/useChildrenData';
import { sendFailureNotification } from 'src/utils/notifications';
import { NotificationTypes } from 'src/enums/Notifications';

const PrepareLearnersScreen = () => {
  const navigate = useNavigate()
  const [learnerCount, setLearnerCount] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const { backgroundColor, backgroundColorExterior } = useResponsive();
  const user = useAppSelector(state => state.user)
  const { isMobile, isTablet } = useResponsive();
  const { setChildPreference} = useChildrenData();

  const handleContinue = async () => {
    try {
      setLoading(true)
      const response = await setChildPreference(learnerCount);
      if(response){
      navigate(parentRoutes.REGISTER_CHILD_ONBOARDING)
    }else{
      sendFailureNotification(NotificationTypes.childPreference, "Something went wrong");
    }
  } catch (error) {
    sendFailureNotification(NotificationTypes.childPreference, "Something went wrong");
  } finally {
    setLoading(false)
  }
  }
  const header = (
    <div className="flex flex-row justify-between">
      <Image src={'/landing/logo.png'} className='max-w-[100px] max-h-[100px]' />
      <></>
    </div>
  );

  return ( 
    <SimpleLayout heading={header}>
      <StyledContainer theme={{ backgroundColor: backgroundColorExterior }}>
        <StyledContainerInset theme={{ backgroundColor: backgroundColor }}>
          <div className="flex h-full w-full flex-col items-center justify-center py-4 small-laptop:py-4 xl:py-10 px-4 sm:px-0">
            <div className='flex flex-col items-center justify-center small-laptop:justify-center xl:justify-start h-full'>
            <Text className='text-xxl '>Hey {user.name}!</Text>
            <Text className='mb-10 mt-2' >Let us know many science learners to prepare for</Text>
            <Button.Group className='gap-8 mb-10' orientation='vertical' w={"100%"}>
                {[{label: 'One', value: 1}, {label: 'Two', value: 2}, {label: 'Three', value: 3}].map((count) => (
                  <UnstyledButton
                    key={count.value}
                    color='green'
                    onClick={() => setLearnerCount(count.value)}
                    className={`small-mobile:h-10 large-mobile:h-12 tablet:h-14 small-laptop:h-12 large-laptop:h-14 xl:h-16 flex items-center rounded-md p-4 justify-between ${learnerCount === count.value ? 'ring-1 ring-primary' : 'shadow-md '}`}
                    
                  >
                    <Text >{count.label}</Text>
                    {learnerCount === count.value && <Icon name='check' size='20' />}
                  </UnstyledButton>
                ))}
                       </Button.Group>
              <Textarea
              placeholder="Type any further details here."
              minRows={isMobile ? 2: isTablet ? 2 : 4}
              maxRows={6}
              radius={"lg"}
              variant='unstyled'
              className="w-full mb-4 small-laptop:mb-4 2xl:mb-10 border-black border rounded-xl px-4 py-2"
            />
            <CustomButton loading={loading} disabled={!learnerCount} onClick={handleContinue} className='w-full'>Continue</CustomButton>
          </div>
          </div>
        </StyledContainerInset>
      </StyledContainer>
    </SimpleLayout>
  );
};

export default PrepareLearnersScreen;