import React, { useState, useEffect } from 'react';
import { Button, Card, Text, Group, List, ThemeIcon, Loader, Badge } from "@mantine/core";
import { useAppSelector } from "src/redux/redux-store/hooks";
import { PriceItem } from "src/interfaces/Payments";
import { ResponseKind } from "src/services/services/MainService";
import { paymentService } from "src/services/services/payment/payment.api";
import Icon from '../common/Icons/Icons/Icon';
import { useNavigate } from 'react-router-dom';
import { parentRoutes } from 'src/navigators/routes/ParentRoutes';
import { PlanCard } from '../Cards/PlanCard';
import { usePayments } from 'src/hooks/usePayment';
import { formatDate } from 'src/utils/helpers';


export const ManageSubscription: React.FC = () => {
  const navigate = useNavigate()
  const user = useAppSelector((state) => state.user);
  const {isPaidUser, userPlan, loading, navigateToPortal, getCurrentPlan } = usePayments()

  const onSubscribe = () => {
    navigate(parentRoutes.PAYMENT);
  };

  const onChangePlan = () => {
    navigate(parentRoutes.PAYMENT);
  };

  const handleCancellation = async () => {
    const url = await navigateToPortal();
    if (url) {
      window.location.href = url;
    }
  }

  const getSubscriptionDetails = () => {
    if (!isPaidUser || !userPlan?.plan_detail) return null;
    return (
      <div className="mb-4">
        <Text size="sm">Plan: {userPlan.plan_detail.product.name}</Text>
        <Text size="sm">Billing: {userPlan.plan_detail.recurring.interval == 'month' ? 'Monthly' : 'Yearly'}</Text>
        <Text size="sm">Renewal: Every {userPlan.plan_detail.recurring.interval == 'year' ? 'year' : 'month'}</Text>
        <Text size="sm">Expires: {formatDate(new Date(userPlan.current_period_end * 1000).toDateString())}</Text>
      </div>
    );
  };

  if (loading) {
    return <Loader size="xl" className="mx-auto mt-8" />;
  }

  return (
    <div className="flex flex-col h-full mt-5 overflow-hidden">
      <Text size="xl" weight={500} className="mb-4">Manage Subscription</Text>
      <div className="flex-grow overflow-hidden">
        <div className="h-full overflow-y-auto">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 pb-8">
            <PlanCard
              title="Core Access"
              isCurrentPlan={user.plan === "Free"}
              price={0}
              description="A free plan with limited functionalities, ideal for casual users or those exploring the service."
              features={[
                'Access to the chatbot interface',
                'Ask question using text (limited to 20 words)',
                'Limited number of questions per day'
              ]}
              billingPeriod=''
              buttonText={isPaidUser ? "Switch to Free" : "Upgrade to Pro"}
              buttonVariant="outline"
              buttonColor={isPaidUser ? "gray" : "blue"}
              onButtonClick={isPaidUser ? handleCancellation : onSubscribe}
            />
            <PlanCard
              title="NextGen Access"
              isCurrentPlan={isPaidUser}
              price={userPlan?.plan_detail ? userPlan.plan_detail.unit_amount / 100 : 51.33}
              description={isPaidUser ? "Your current Pro plan" : "A premium plan with unlimited functionalities. Explore our Solo, Dual and Trio learner plans."}
              features={[
                'Access to the chatbot interface',
                'Ask question using text or image',
                'Unlimited number of questions per day',
                'Access to the question bank'
              ]}
              billingPeriod={userPlan?.plan_detail?.recurring.interval ? userPlan?.plan_detail?.recurring.interval : ''}
              buttonText={isPaidUser ? "Change Plan" : "Subscribe"}    //REITERATE
              buttonVariant='default'
              buttonColor="blue"
              onButtonClick={isPaidUser ? onChangePlan : onSubscribe}
              additionalButtons={
                isPaidUser && (
                  <Button variant="outline" color="red" fullWidth onClick={handleCancellation} mt="md">
                    Cancel Plan
                  </Button>
                )
              }
              subscriptionDetails={getSubscriptionDetails()}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageSubscription
