import { useCallback } from 'react';
import { useAppSelector, useAppDispatch } from "src/redux/redux-store/hooks";
import { setPrices, setGroupedPrices, setUserPlan, setLoading, setError } from 'src/redux/redux-store/payment/paymentReducer'
import { paymentService } from "src/services/services/payment/payment.api";
import { ResponseKind } from "src/services/services/MainService";
import { GroupedPrices, PriceItem } from 'src/interfaces/Payments';

export const usePayments = () => {
  const dispatch = useAppDispatch();
  const { prices, groupedPrices, userPlan, loading, error } = useAppSelector((state) => state.payment);
  const user = useAppSelector((state) => state.user);

  const fetchPrices = useCallback(async () => {
    dispatch(setLoading(true));
    dispatch(setError(null));
    try {
      const response = await paymentService.getPrices();
      if (response.kind === ResponseKind.OK && response.data) {
        dispatch(setPrices(response.data.prices));
        const grouped = response.data.prices.reduce((acc: GroupedPrices, price: PriceItem) => {
          const productName = price.product.name;
          if (!acc[productName]) {
            acc[productName] = [];
          }
          acc[productName].push(price);
          return acc;
        }, {});
        console.log("Grouped array: ", grouped)
        dispatch(setGroupedPrices(grouped));
      } else {
        dispatch(setError("Failed to fetch prices"));
      }
    } catch (err) {
      dispatch(setError("An error occurred while fetching prices"));
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch]);

  const getCurrentPlan = useCallback(async () => {
    dispatch(setLoading(true));
    dispatch(setError(null));
    try {
      const response = await paymentService.getCurrentPlan();
      if (response.kind === ResponseKind.OK && response.data) {
        dispatch(setUserPlan(response.data));
        console.log("response for plan", response.data)
      } else {
        dispatch(setError("Failed to fetch plan"));
      }
    } catch (err) {
      dispatch(setError("An error occurred while fetching user plan"));
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch]);

  const createSession = useCallback(async (priceId: string) => {
    try {
      const response = await paymentService.createPaymentSession(priceId);
      if (response.kind === ResponseKind.OK && response.data) {
        console.log("price link", response.data);
        return response.data.url;
      } else {
        throw new Error("Failed to create payment session");
      }
    } catch (error) {
      console.error("Error creating payment session:", error);
      throw error;
    }
  }, []);

  const cancelSubscription = useCallback(async () => {
    // Implement cancel subscription logic here
    console.log("Cancelling subscription");
    // You'll need to implement the actual API call to cancel the subscription
  }, []);

  const navigateToPortal = useCallback(async () => {
    const response = await paymentService.createPortalSession();
    if (response.kind === ResponseKind.OK && response.data) {
      return response.data;
    }
  }, []);

  return {
    prices,
    groupedPrices,
    userPlan,
    navigateToPortal,
    loading,
    error,
    fetchPrices,
    getCurrentPlan,
    createSession,
    cancelSubscription,
    isPaidUser: user.plan !== "Free",
  };
};