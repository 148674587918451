import { useTheme } from "@emotion/react";
import { Avatar, AvatarStylesNames, AvatarStylesParams, Styles, Text, useMantineTheme } from "@mantine/core"
import useResponsive from "src/hooks/useResponsive";
import { User } from "src/interfaces";
import { COLORS, FONT_SIZES } from "src/utils/styles";

interface ProfileCardProps{
  name: string
  username: string
  avatar: string
  width?: string
  height?: string
}

export const ProfileCard = (props: ProfileCardProps) => {
    const {name, username, avatar, width, height} = props

    const extractInitials = (userName: string) => {
        if (!userName) return ""; // Return empty if no name is provided
    
        // Split the name into parts and filter out any empty results from extra spaces
        const nameParts = userName.trim().split(/\s+/);
    
        // Check if we have at least two parts from which to construct initials
        if (nameParts.length > 1) {
          // Use the first letter of the first and last name parts
          return (
            nameParts[0][0] + nameParts[nameParts.length - 1][0]
          ).toUpperCase();
        } else {
          // If there's only one part, just return the first letter of this part
          return nameParts[0][0].toUpperCase();
        }
      };

    return(<div className="flex flex-row space-x-2 px-2 items-center">
        <div className={`w-${width ? width : '16'} h-${height ? height : '16'} bg-gray-200 rounded-full mr-4`}>
          {avatar ? (
            <img src={avatar} alt={name} className="w-full h-full rounded-full object-cover" />
          ) : (
            <div className="w-full h-full flex items-center justify-center text-2xl font-bold text-gray-400">
              {extractInitials(name)}
            </div>
          )}
        </div>
        <div>
          <Text size="xl" weight={700}>{name}</Text>
          <Text size="sm" color="dimmed">{username}</Text>
        </div>
  
      </div>)
}
