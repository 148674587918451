// src/services/MainService/api.ts

import { ApisauceInstance, ApiResponse, create } from "apisauce";
import { ApiConfig } from "./api.types";
import Config from "src/config";
import { getStorageToken, setStorageToken, clearStorageToken } from "src/utils/auth.storage";
import { UserToken } from "src/interfaces";
import { ErrorKind, getGeneralApiProblem } from "./apiProblem";
import { ResponseKind, ResponseType } from "./api.types";
import { authService } from "../auth/auth.api";

export const DEFAULT_API_CONFIG: ApiConfig = {
  url: Config.API_URL,
  timeout: 100000,
};

export class Api {
  apisauce: ApisauceInstance;
  config: ApiConfig;

  constructor(config: ApiConfig = DEFAULT_API_CONFIG) {
    this.config = config;
    this.apisauce = create({
      baseURL: this.config.url,
      timeout: this.config.timeout,
    });

    this.setupInterceptors();
  }

  private setupInterceptors(): void {
    this.apisauce.axiosInstance.interceptors.request.use(
      async (config: any) => {
        if (config.url?.includes('/refresh') || config.url?.includes('/verify') || config.url?.includes('/token') || config.url?.includes('blob.core')) {
          return config;
        }

        const token = await getStorageToken();
        if (token) {
          const todaysDate = new Date();
          const tokenExpiry = new Date(token.expires_at);

          if (tokenExpiry.getTime() > todaysDate.getTime()) {
            config.headers.Authorization = `${token.token_type} ${token.access_token}`;
          } else {
            try {
              const refreshResponse = await authService.getRefreshToken(token.refresh_token);
              if (refreshResponse.kind === ResponseKind.OK && refreshResponse.data) {
                await setStorageToken(refreshResponse.data);
                config.headers.Authorization = `${refreshResponse.data.token_type} ${refreshResponse.data.access_token}`;
              } else {
                throw new Error('Token refresh failed');
              }
            } catch (error) {
              console.error('Token refresh error:', error);
              await clearStorageToken();
              window.dispatchEvent(new Event('logout'));
              throw error;
            }
          }
        }

        return config;
      },
      (error) => Promise.reject(error)
    );

    this.apisauce.axiosInstance.interceptors.response.use(
      (response) => response,
      async (error) => {
        const originalRequest = error.config;

        if (error.url?.includes('/refresh') || error.url?.includes('/verify') || error.url?.includes('/token') || error.url?.includes('blob.core')) {
          return error;
        }

        if (error.response?.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          try {
            const token = await getStorageToken();
          
            if (token) {
              const refreshResponse = await authService.getRefreshToken(token.refresh_token);
              if (refreshResponse.kind === ResponseKind.OK && refreshResponse.data) {
                await setStorageToken(refreshResponse.data);
                originalRequest.headers.Authorization = `Bearer ${refreshResponse.data.access_token}`;
                return this.apisauce.axiosInstance(originalRequest);
              }
            }
            throw new Error('Token refresh failed');
          } catch (refreshError) {
            console.error('Token refresh failed:', refreshError);
            await clearStorageToken();
            window.dispatchEvent(new Event('logout'));
            return Promise.reject(refreshError);
          }
        }
        return Promise.reject(error);
      }
    );
  }

  async getResponse<T>(
    response: ApiResponse<T>,
    hideError?: boolean
  ): Promise<ResponseType<T>> {
    if (!response.ok) {
      const problem = getGeneralApiProblem(response);

      if (problem.kind === ErrorKind.REJECTION_ERROR) {
        return {
          kind: ErrorKind.REJECTION_ERROR,
          errors: problem?.errors?.data?.detail,
        };
      }else if (problem.kind === ErrorKind.TOO_MANY_REQUESTS) {
        return {
          kind: ErrorKind.TOO_MANY_REQUESTS,
          errors: problem?.errors?.data?.detail,
        };
      } else {
        return {
          kind: ErrorKind.NOT_FOUND_ERROR,
          errors: "Oops something went wrong",
        };
      }
    }

    const res: any = response.data;

    return { kind: ResponseKind.OK, data: res };
  }

  // Add other methods as needed (get, post, put, delete, etc.)
}

export const api = new Api();