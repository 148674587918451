import styled from "@emotion/styled";

interface ThemeProps {
  theme: {
    backgroundColor: string;
  };
}

export const StyledContainer = styled.div<ThemeProps>`
  background-color: ${(props) => props.theme.backgroundColor || 'white'};
  height: 100%;
  display: flex; // Ensuring flex is applied not just in media query
  align-items: center; 
  justify-content: center; // Center horizontally
  margin: 0;
  padding: 0;
  
  @media (max-width: 768px) {
    height: 100%;
  }
`;