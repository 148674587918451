import React from 'react';
import { Badge, Button, Card, Text } from "@mantine/core";

interface ProPlanCardProps {
  title: string;
  price: number;
  interval: string;
  id: string;
  selectedPlan: string;
  onSelect: (id: string) => void;
  description: string;
  discounted_price: number;
  isCurrentPlan: boolean;
}

export const ProPlanCard: React.FC<ProPlanCardProps> = ({ 
  title, 
  price, 
  description, 
  interval,
  id,
  discounted_price,
  selectedPlan,
  onSelect,
  isCurrentPlan 
}) => (
  <div 
  className={`p-5 rounded-lg shadow-md cursor-pointer ${selectedPlan === id ? 'border-2 border-primary' : ''}`}
  onClick={() => onSelect(id)}>
    <div className="flex justify-between">
      <div className='flex flex-row gap-2 items-center'>
      <Text size="lg" weight={700}>{title}</Text>
      {isCurrentPlan && <Badge color="green">Current Plan</Badge>}
      </div>
   
      <div className="">
      <Text size="xl" weight={700} className="inline-block mr-2">
        <span className="text-primary font-bold">${interval == 'year' ? (discounted_price / 12).toFixed(2) : discounted_price.toFixed(2)}</span>{' '}
        <span className="text-sm font-normal text-gray-500">/month</span>
      </Text>

      <Text size="md" className="inline-block line-through text-gray-400">
        ${interval == "year" ? (price / 12).toFixed(2) : price}
      </Text>
  
    </div>

    </div>
    {/* <Text size="sm" color="dimmed" className="mb-4">{description}</Text> */}
  </div>
);