import React from 'react';
import { Modal, Text } from '@mantine/core';
import { EditChildForm } from 'src/components/Forms/Child/EditChildForm';
import { ChildrenData, ChildUpdationData } from 'src/interfaces/Children';
import useResponsive from "src/hooks/useResponsive";

interface UpdateChildModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedChild: ChildrenData | null;
  onUpdateChild: (updatedChildData: Partial<ChildUpdationData>) => void;
}

export const UpdateChildModal: React.FC<UpdateChildModalProps> = ({
  isOpen,
  onClose,
  selectedChild,
  onUpdateChild,
}) => {
  const { isMobile } = useResponsive();

  return (
    <Modal
      opened={isOpen}
      onClose={onClose}
      title={
        <Text size="xl" weight={700}>
          Update Child Information
        </Text>
      }
      fullScreen={isMobile}
      size="lg"
      radius="lg"
      styles={{
        content: {
          padding: '10px',
        },
      }}
    >
      {selectedChild && (
        <EditChildForm
          user={selectedChild}
          onUpdate={onUpdateChild}
        />
      )}
    </Modal>
  );
};