import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PriceItem, GroupedPrices, UserPlan } from "src/interfaces/Payments";

interface PaymentsState {
  prices: PriceItem[];
  groupedPrices: GroupedPrices | null;
  userPlan: UserPlan | null;
  loading: boolean;
  error: string | null;
}

const initialState: PaymentsState = {
  prices: [],
  groupedPrices: null,
  userPlan: null,
  loading: false,
  error: null,
};

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setPrices: (state, action: PayloadAction<PriceItem[]>) => {
      state.prices = action.payload;
    },
    setGroupedPrices: (state, action: PayloadAction<GroupedPrices>) => {
      state.groupedPrices = action.payload;
    },
    setUserPlan: (state, action: PayloadAction<UserPlan>) => {
      state.userPlan = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<string | null>) => {
      state.error = action.payload;
    },
    clearPaymentState: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export const {
  setPrices,
  setGroupedPrices,
  setUserPlan,
  setLoading,
  setError,
  clearPaymentState,
} = paymentsSlice.actions;

export default paymentsSlice.reducer;