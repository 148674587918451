import { BackgroundImage } from '@mantine/core';
import React from 'react';
import useResponsive from "src/hooks/useResponsive";

interface AuthLayoutProps {
  visualComponent: React.ReactNode;
  formComponent: React.ReactNode;
}

export const AuthLayout: React.FC<AuthLayoutProps> = ({ visualComponent, formComponent }) => {
  const { isDesktop, isMobile } = useResponsive();


  return (
    <div className={`flex ${isDesktop ? 'flex-row' : 'flex-col'} h-screen`}>
      <BackgroundImage src="/images/doodles.png" className={`w-full ${!isDesktop ? 'p-4 hidden' : 'lg:w-1/2 p-8'} flex flex-col justify-center items-center`}>
        {visualComponent}
      </BackgroundImage>
      <div className={`w-full ${!isDesktop ? 'p-4' : 'lg:w-1/2 p-8 rounded-2xl shadow-lg'} flex flex-col justify-start pt-10 md:pt-0 md:justify-center items-center`}>
        {formComponent}
      </div>
    </div>
  );
};