import React from 'react';
import { Select } from '@mantine/core';
import { ChildrenData } from 'src/interfaces/Children';

interface ChildSelectProps {
  children: ChildrenData[];
  onSelectChild: (username: string) => void;
  value: string;
}

const ChildSelect: React.FC<ChildSelectProps> = ({ children, onSelectChild, value }) => {
  const childOptions = children.map((child) => ({
    value: child.username,
    label: `${child.name}`,
  }));

  return (
    <Select
      className='mb-4'
      placeholder="Choose a child"
      data={childOptions}
      onChange={onSelectChild}
      value={value}
      clearable
    />
  );
};

export default ChildSelect;