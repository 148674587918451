import React from 'react';
import { Card, Text, Button, Badge } from '@mantine/core';
import { getPlanName, UserPlan } from 'src/interfaces/Payments';
import { CustomButton } from '../common/Buttons/CustomButton';
import { formatDate } from 'src/utils/helpers';

interface PlanInfoCardProps {
    paid?: boolean;
    plan: UserPlan | null,
    trial_days?: number;
    trial_expired?: boolean;
    onUpgrade: () => void;
}

export const PlanInfoCard: React.FC<PlanInfoCardProps> = ({ paid, plan, trial_days, trial_expired, onUpgrade }) => {
    let planExpiryDate = '';
    if (plan?.current_period_end) {
        planExpiryDate = formatDate(new Date(plan?.current_period_end * 1000).toDateString());
    }
    if (paid) {
        return (
            <div className='flex bg-primary rounded-2xl p-4 flex-col gap-2'>
                <div className="flex justify-between items-start">
                    <Text className='text-white text-base font-semibold'>{'Premium Access'}</Text>
                    <Badge color="green">{getPlanName(plan?.plan)}</Badge>

                </div>
                {plan?.current_period_end && <Text className='text-white text-sm text-center font-semibold'>Your subscription expires on {planExpiryDate}</Text>}
                {/* <CustomButton onClick={onUpgrade} leftIcon='star' size='sm' variant='outline' className='bg-white' >Change Plan</CustomButton> */}
                {/* <Text size="sm" className="mb-4">{plan?.plan_detail?.recurring.interval == 'month' ? 'Monthly' : 'Yearly'}</Text> */}
            </div>
        )
    }

    return (
        <div className='flex bg-primary rounded-2xl p-4 flex-col gap-2'>
            <div className="flex justify-between items-start">
                <Text className='text-white text-base font-semibold'>{trial_expired ? 'Limited Access' : 'Premium Access'}</Text>
                <Badge color="green">{trial_expired ? 'Free Tier' : 'Free Trial'}</Badge>

            </div>
            {!trial_expired && <Text className='text-white text-sm text-center font-semibold'>You have {trial_days} Days Left</Text>}
            <CustomButton onClick={onUpgrade} leftIcon='star' size='sm' variant='outline' className='bg-white' >Upgrade Now</CustomButton>
            {/* <Text size="sm" className="mb-4">{plan?.plan_detail?.recurring.interval == 'month' ? 'Monthly' : 'Yearly'}</Text> */}
        </div>
    )
};