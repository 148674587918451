import React, { forwardRef } from 'react';
import { Image, Text } from '@mantine/core';
import Icon from '../Icons/Icons/Icon';

interface PhoneInputFieldProps extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
  label?: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  inputContainerClassName?: string;
}

export const PhoneInputField = forwardRef<HTMLInputElement, PhoneInputFieldProps>(({
  label,
  error,
  className = '',
  inputClassName = '',
  inputContainerClassName = '',
  onChange,
  value,
  ...props
}, ref) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    // Create a new event with the raw value (without prefix handling)
    const newEvent = {
      ...e,
      target: {
        ...e.target,
        value: inputValue
      }
    };
    
    onChange?.(newEvent as React.ChangeEvent<HTMLInputElement>);
  };

  return (
    <div className={`relative mb-4 ${className}`}>
      {label && (
        <Text component="label" className="block mb-2 text-sm font-medium text-gray-900">
          {label}
        </Text>
      )}
      <div className={`relative h-16 ${inputContainerClassName}`}>
        <div className="absolute inset-y-0 left-0 flex items-center pl-3">
        <Image maw={25} src={'/landing/flag.png'} />
        </div>
        <div className="absolute inset-y-0 left-8 flex items-center pl-3 border-r border-gray-300 pr-3">
          <span className="text-gray-500">+65</span>
        </div>
        <input
          ref={ref}
          type="tel"
          onChange={handleChange}
          value={value}
          className={`
            w-full h-full bg-gray-50 border-1/2 rounded-lg shadow-md
            placeholder-gray-400 focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 font-sans
            pl-24 pr-4
            ${error ? 'border-red-500' : ''}
            ${inputClassName}
          `}
          {...props}
        />
      </div>
      {error && (
        <Text className="mt-1 text-sm text-red-500">
          {error}
        </Text>
      )}
    </div>
  );
});

PhoneInputField.displayName = 'PhoneInputField';