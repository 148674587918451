import { UserRole, role } from "src/enums/User";
import { User } from "src/interfaces";
import { dateDiffInDays } from "src/utils/helpers";

export const tranformUser = (user: User): User => {

    if(user.role == UserRole.parent){
        let trial_expired;
        let trial_days;

        if(user.trial_expiry){
            const today = new Date();
            const currentTime = new Date().getTime();
            const expiryDate = new Date(user.trial_expiry);
            const expiryTime = expiryDate.getTime();
            
            // trial_expired = currentTime > expiryTime;
            trial_expired = user.plan == "Free" && user.paid == false;
            
            if(!trial_expired){
                trial_days = dateDiffInDays(today, expiryDate);
            }
        }
        
        return{
            azureStorageToken: user.azureStorageToken,
            name: user.name,
            email: user.email,
            paid: user.paid,
            trial_expired: user.trial_expiry ? trial_expired : false,
            plan: user.plan,
            trial_days: trial_days || 0,
            walkthrough_completed: user.walkthrough_completed,
            trial_expiry: user.trial_expiry,
            child_preference: user.child_preference,
            role: user.role,
            id: user.id,
            profile_picture_url: user.profile_picture_url
        }
    }


    return{
        name: user.name,
        azureStorageToken: user.azureStorageToken,
        email: user.email,
        date_of_birth: user.date_of_birth,
        trial_expired: user.plan == "Free" && user.paid == false,
        paid: user.paid,
        walkthrough_completed: user.walkthrough_completed,
        plan: user.plan,
        role: user.role,
        id: user.id,
        level: user.level,
        child_school: user.child_school,
        profile_picture_url: user.profile_picture_url
    }
}