import { ApiResponse } from "apisauce"

export enum ErrorKind {
  TIMEOUT = "timeout",
  CANNOT_CONNECT = "cannot-connect",
  SERVER = "server",
  UNKNOWN = "unknown",
  AUTHORIZATION_ERROR = "unauthorized",
  FORBIDDEN_ERROR = "forbidden",
  NOT_FOUND_ERROR = "not-found",
  CONFLICT_ERROR = "conflict",
  TOO_MANY_REQUESTS = "too-many-requests",
  REJECTION_ERROR = "rejected",
  BAD_DATA_ERROR = "bad-data",
  CONNECTION_ERROR = "CONNECTION_ERROR",
  NETWORK_ERROR = "NETWORK_ERROR",
  TIMEOUT_ERROR = "TIMEOUT_ERROR",
  SERVER_ERROR = "SERVER_ERROR",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  CLIENT_ERROR = "CLIENT_ERROR",
  CANCEL_ERROR = "CANCEL_ERROR",
}

export enum ResponseKind {
  OK = "ok",
}

export type GeneralApiProblem =
  /**
   * Times up.
   */
  | { kind: "timeout"; temporary: true }
  /**
   * Cannot connect to the server for some reason.
   */
  | { kind: "cannot-connect"; temporary: true }
  /**
   * The server experienced a problem. Any 5xx error.
   */
  | { kind: "server"; errors: any }
  /**
   * We're not allowed because we haven't identified ourself. This is 401.
   */
  | { kind: "cancel_error"; errors?: any }
  /**
   * We're not allowed because we haven't identified ourself. This is 401.
   */
  | { kind: "unauthorized"; errors: any }
  /**
   * We don't have access to perform that request. This is 403.
   */
  | { kind: "forbidden"; errors: any }
  /**
   * Unable to find that resource.  This is a 404.
   */
  | { kind: "not-found"; errors: any }
  /**
   * All other 4xx series errors.
   */
  | { kind: "too-many-requests"; errors: any }
  /**
   * All other 4xx series errors.
   */
  | { kind: "rejected"; errors: any }
  /**
   * Something truly unexpected happened. Most likely can try again. This is a catch all.
   */
  | { kind: "unknown"; temporary: true; errors: any }
  /**
   * The data we received is not in the expected format.
   */
  | { kind: "bad-data"; error: any }

/**
 * Attempts to get a common cause of problems from an api response.
 *
 * @param response The api response.
 */
export function getGeneralApiProblem(response: ApiResponse<any>): GeneralApiProblem{
  switch (response.problem) {
    case "CONNECTION_ERROR":
      return { kind: "cannot-connect", temporary: true }
    case "NETWORK_ERROR":
      return { kind: "cannot-connect", temporary: true }
    case "TIMEOUT_ERROR":
      return { kind: "timeout", temporary: true }
    case "SERVER_ERROR":
      return { kind: "server", errors: response }
    case "UNKNOWN_ERROR":
      return { kind: "unknown", errors: response, temporary: true }
    case "CLIENT_ERROR":
      switch (response.status) {
        case 400:
          return { kind: "rejected", errors: response }
        case 401:
          return { kind: "unauthorized", errors: response }
        case 403:
          return { kind: "forbidden", errors: response }
        case 404:
          return { kind: "not-found", errors: response }
        case 429:
          return { kind: 'too-many-requests', errors: response }
        default:
          return { kind: "rejected", errors: response }
      }
    case ErrorKind.CANCEL_ERROR:
      return { kind: "cancel_error" }
  }

  return {kind: "unknown", temporary: true, errors: []}
}
