 import { createSlice } from "@reduxjs/toolkit"
import type { PayloadAction } from "@reduxjs/toolkit"
import { store } from "../configureStore"
import { AdminAppSettings, AppSettings } from "src/interfaces/AppSettings"
import { User } from "src/interfaces"
import { getState } from "../types"
import { Dispatch } from "react"
import { authService } from "src/services/services/auth/auth.api"
import { ResponseKind } from "src/services/services/MainService"
import { tranformUser } from "./transformer"
import { ChildLevel } from "src/enums/Child"
import { UserRole } from "src/enums/User"

const initialState: User = {
  email: "",
  id: "",
  plan: "",
  paid: false,
  level: null,
  profile_picture_url: '',
  child_school: "",
  child_preference: 0,
  trial_expired: false, 
  trial_days: 0,
  trial_expiry: '',
  name: "",
  azureStorageToken: ""
}

const user = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User>) => {
      state.email = action.payload.email
      state.id = action.payload.id
      state.paid= action.payload.paid
      state.trial_expiry = action.payload.trial_expiry
      state.profile_picture_url = action.payload.profile_picture_url
      state.level = action.payload.level
      state.child_school = action.payload.child_school
      state.walkthrough_completed = action.payload.walkthrough_completed
      state.plan = action.payload.plan
      state.child_preference = action.payload.child_preference
      state.trial_days = action.payload.trial_days
      state.trial_expired = action.payload.trial_expired
      state.role = action.payload.role
      state.name = action.payload.name
    },
    setWalkthroughCompleted: (state, action: PayloadAction<boolean>) => {
      state.walkthrough_completed = action.payload
    },
    clearUser: (state) => {
      state.azureStorageToken = ''
      state.email = ''
      state.paid = false
      state.trial_days = 0
      state.child_preference = 0
      state.plan = ''
      state.level = null
      state.profile_picture_url = ''
      state.child_school = ''
      state.trial_expired = false
      state.id = ''
      state.role = 1
      state.name = ''
    },
    setAzureToken: (state, action: PayloadAction<string>) => {
      state.azureStorageToken = action.payload
    },
  },
})

const {setUser, setAzureToken, clearUser, setWalkthroughCompleted} = user.actions


export const updateUser = (user: User) => ({
  type: setUser.type,
  payload: user,
})

export const updateWalkthroughCompleted = (completed: boolean) => ({
  type: setWalkthroughCompleted.type,
  payload: completed,
})

export const resetUser = () => ({
  type: clearUser.type,
})


export const handleUserUpdate = () => {
  return async (dispatch: Dispatch<any>, state: getState) => {
    try {
      const response = await authService.getUserData()

      if (response.kind === ResponseKind.OK && response.data) {
        const user = tranformUser(response.data)
        dispatch(updateUser(user))
        return user // Return the updated user data
      } else {
        throw new Error("Failed to fetch user data")
      }
    } catch (error) {
      // console.log("Could not update user in the redux store", error);
      return null // Return null in case of an error
    }
  }
}

export const handleWalkthroughUpdate = () => {
  return async (dispatch: Dispatch<any>, state: getState) => {
    try {
      const response = await authService.updateWalkthrough()
      console.log("Walkthrough response", response)
      if (response.kind === ResponseKind.OK && response.data) {
        dispatch(updateWalkthroughCompleted(true))
        return response.data
      } else {
        throw new Error("Failed to fetch user data")
      }
    } catch (error) {
      // console.log("Could not update user in the redux store", error);
      return null // Return null in case of an error
    }
  }
}

export const updateAzureToken = (token: string) => ({
  type: setAzureToken.type,
  payload: token,
})


export default user.reducer
