import React from 'react';
import { ActionIcon, Image, useMantineTheme } from '@mantine/core';
import ReactMarkdown from 'react-markdown';
import Icon from '../common/Icons/Icons/Icon';
import { isValidJSON } from 'src/utils/helpers';

interface ReadOnlyBotMessageProps {
  message: string;
  index: number;
  onFlagMessage: (index: number) => void;
}

const ReadOnlyBotMessage: React.FC<ReadOnlyBotMessageProps> = ({
  message,
  index,
  onFlagMessage,
}) => {
  const theme = useMantineTheme();


  if (isValidJSON(message)) {
    const formattedMessage = JSON.parse(message)
    return (
      <div className="flex items-start space-x-4">
        <Image src={'/icons/favicon.png'} mah={40} maw={40} className="self-start mt-2" />
        <div className="flex-1">
          <div className="rounded-lg bg-white border border-gray-200 w-full md:max-w-[500px] p-4">
            <div className="text-sm text-black">
              <ReactMarkdown>{formattedMessage.text}</ReactMarkdown>
              {formattedMessage.image_url && (
                <div className="mt-3 border border-gray-200 rounded-lg p-2">
                  <Image 
                    src={formattedMessage.image_url} 
                    radius="sm" 
                    className="w-full"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="w-full">
    <div className="flex flex-row items-end justify-between gap-4">
      <div className=" flex flex-row gap-4">
        <Image src={'/icons/favicon.png'} mah={40} maw={40} className="self-center" />
        <div className={`flex flex-col items-start justify-start`}>
          <div className={`text-md leading-[22px] text-left inline-block  border-1/2  rounded-lg bg-white px-8 py-3`}>
            <ReactMarkdown>{message}</ReactMarkdown>
          </div>
        </div>
      </div>
      <ActionIcon size="1rem" onClick={() => onFlagMessage(index)}>
          <Icon name="flag" size="20px" />
        </ActionIcon>
    </div>
  </div>
  );
};

export default ReadOnlyBotMessage;