import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ActionIcon, Box, Button, Text } from '@mantine/core';
import Icon from '../common/Icons/Icons/Icon';

interface FileUploadModalProps {
  isOpen: boolean;
  onClose: () => void;
  onFileUpload: (file: File) => void;
  allowedFileTypes?: string;
  maxFileSize?: number;
  customClasses?: {
    modal?: string;
    title?: string;
    uploadArea?: string;
    previewArea?: string;
    button?: string;
    errorText?: string;
  };
}

const FileUploadModal: React.FC<FileUploadModalProps> = ({
  isOpen,
  onClose,
  onFileUpload,
  allowedFileTypes = "image/*, application/pdf",
  maxFileSize = 5 * 1024 * 1024, // 5MB default
  customClasses = {},
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      if (file.size > maxFileSize) {
        setError(`File size exceeds the limit of ${maxFileSize / (1024 * 1024)}MB`);
        return;
      }
      setSelectedFile(file);
      setError(null);
    }
  };

  const handleUpload = () => {
    if (selectedFile) {
      onFileUpload(selectedFile);
      setSelectedFile(null);
      onClose();
    }
  };

  const renderPreview = () => {
    if (!selectedFile) return null;
    if (selectedFile.type.startsWith('image/')) {
      return (
        <img
          src={URL.createObjectURL(selectedFile)}
          alt="File preview"
          className="max-w-full max-h-full object-contain"
        />
      );
    }
    return <div className='flex flex-col items-center justify-center'>
        <Icon name='doc' color='gray' size='40' />
        <Text className="mt-2 text-gray-400">{selectedFile.name}</Text></div>;
  };

  if (!isOpen) return null;

  return (
    <div className="absolute w-full md:w-2/3 bottom-full right-0 md:right-10 bg-white border border-gray-300 rounded-xl shadow-md mb-2 z-50">
    <Box p="lg">
        <div className=' flex flex-row  mb-4 justify-between'>
        <Text className={`text-lg font-semibold ${customClasses.title || ''}`}>
          
        </Text>
        <ActionIcon onClick={onClose}>
        <Icon name='cross' />
        </ActionIcon>
        </div>
      
        <div
          className={`border-2 border-dashed bg-gray-100 h-56 flex items-center justify-center border-gray-400 rounded-md text-center mb-4
            ${customClasses.uploadArea || ''}`}
        >
          <input
            type="file"
            onChange={handleFileChange}
            accept={allowedFileTypes}
            className="hidden"
            id="fileInput"
          />
           {!selectedFile && <Text size={"lg"}>
          Upload document or image
        </Text>}

        {selectedFile ? <div className=' h-full w-full flex justify-center'>
        {renderPreview()}
        </div> : <></>}
       
   
     
        </div>
        {error && <Text className={`text-red-500 mb-2 ${customClasses.errorText || ''}`}>{error}</Text>}
       
        <label htmlFor="fileInput">
            <Button leftIcon={<Icon name='upload' size='20' />} component='span' variant= 'white'>
              <Text className='text-wrap text-xs sm:text-sm md:text-md'>Upload documents or images from your system</Text>
            </Button>
          </label>
        <div className="flex justify-end mt-4">
        
          <Button
            onClick={handleUpload}
            disabled={!selectedFile}
            className={`bg-primary hover:bg-blue-950 text-white disabled:bg-gray-300
              disabled:cursor-not-allowed ${customClasses.button || ''}`}
          >
            Confirm
          </Button>
        </div>
      </Box>
    </div>
  );
};

export default FileUploadModal;