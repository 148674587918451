// ChangePasswordModal.tsx
import React from 'react';
import { Modal } from '@mantine/core';
import { PasswordChangeForm } from '../Forms/ChangePassword';

interface ChangePasswordModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const ChangePasswordModal: React.FC<ChangePasswordModalProps> = ({ isOpen, onClose }) => {
  const handleSubmit = (data: { oldPassword: string; newPassword: string; confirmPassword: string }) => {
    // Implement password change logic here
    console.log('Password change submitted', data);
    onClose();
  };

  return (
    <Modal opened={isOpen} radius={"lg"} onClose={onClose} title="Change Password" centered
    styles={{
      content: {
        padding: '10px',
      },
    }}
    >
      <PasswordChangeForm onSubmit={handleSubmit} onCancel={onClose} />
    </Modal>
  );
};

export default ChangePasswordModal;