
import { useAppDispatch, useAppSelector } from "src/redux/redux-store/hooks";
import React, { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { clearChatState, clearMessages, resetUser, setSelectedSession } from "src/redux/redux-store";
import { ResponseKind } from "src/services/services/MainService";
import useViewportHeight from "src/hooks/useViewPortHeight";
import { useChat } from "src/hooks/useChat";
import { ChatSession } from "src/interfaces/Chat";
import { authService } from "src/services/services/auth/auth.api";
import { authRoutes } from "src/navigators/routes/auth";
import { clearToken } from "src/utils/storage";
import { useAuth } from "src/hooks/useAuth";
import { BaseSidebar } from "./BaseSidebar";
import { ChatSessionsList } from "src/components/Chat/ChatSessionsList";
import { ConfirmFeedbackModal } from "src/components/Modals/ConfirmationModal";
import { Text, Tooltip } from "@mantine/core";
import ReactJoyride from "react-joyride";

interface SideBarProps {
  opened: boolean;
}


const ParentChatSidebar = React.memo((props: SideBarProps) => {
  const dispatch = useAppDispatch()
  const chatSessions = useAppSelector((state) => state.chat.sessions)
  const selectedSession = useAppSelector((state) => state.chat.selectedSession)
  const user = useAppSelector((state) => state.user)
  const trial_days = user.trial_days;
  const [paymentUrl, setPaymentUrl] = useState("");
  const { newSession, deleteSession } = useChat()
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false)
  const [selectedChat, setSelectedChat] = useState<string>('')
  const { logout } = useAuth()
  // useViewportHeight()

  const groupedChats = useMemo(() => {
    const now = new Date();
    const today = now.toDateString();

    const yesterday = new Date(now.setDate(now.getDate() - 1)).toDateString();

    return chatSessions.reduce<Record<string, ChatSession[]>>((acc, chat) => {
      const chatDate = new Date(chat.created_at);
      let group: string;

      if (chatDate.toDateString() === today) {
        group = 'Today';
      } else if (chatDate.toDateString() === yesterday) {
        group = 'Yesterday';
      } else {
        group = 'Last Week';
      }

      if (!acc[group]) {
        acc[group] = [];
      }
      acc[group].push(chat);
      return acc;
    }, {});
  }, [chatSessions]);

  const selectSession = async (chatSession: ChatSession) => {
    dispatch(setSelectedSession(chatSession))
  }

  const deleteChat = async (id: string) => {
    setSelectedChat(id) 
    setVisible(true)
  }

  const logOut = async () => {
    const response = await authService.signOut();

    if (response.kind == ResponseKind.OK) {
      navigate(authRoutes.LOGIN, { replace: true });
      clearToken();
      dispatch(resetUser());
      dispatch(clearChatState());
      logout();
    }
  };

  const goToProfile = async () => {
    const response = await authService.signOut();

    if (response.kind == ResponseKind.OK) {
      navigate(authRoutes.LOGIN, { replace: true });
      clearToken();
      dispatch(resetUser());
      dispatch(clearChatState());
      logout();
    }
  };

  return (
    <BaseSidebar fullName={user.name} screenName="Home" >
      <ConfirmFeedbackModal
        title="Delete Chat"
        feedback="Are you sure you want to delete this chat?"
        confirmLabel="Delete Chat"
        visible={visible}
        onClose={() => setVisible(false)}
        onConfirm={() => deleteSession(selectedChat)}
      />
      <ChatSessionsList
        hideNewChatButton={true}
        selectedSession={selectedSession.id}
        onDeleteSession={deleteChat}
        onSelectSession={selectSession}
        onNewSession={newSession}
        groupedChats={groupedChats}
      />
    </BaseSidebar>
  );
});

export default ParentChatSidebar;


//   <UserStatusCard
//   paid={user?.paid || false}
//   handlePayment={handleButtonClick}
//   trialDays={user.trial_days}
// />