import React, { useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { ActionIcon, Box, Button, Modal, Text, UnstyledButton } from '@mantine/core';
import Icon from '../common/Icons/Icons/Icon';

interface TrialExpiryNoticeModalProps {
  isOpen?: boolean;
  onClose: () => void;
  onUpgrade: () => void;
  customClasses?: {
    modal?: string;
    title?: string;
    uploadArea?: string;
    previewArea?: string;
    button?: string;
    errorText?: string;
  };
}

const TrialExpiryNoticeModal: React.FC<TrialExpiryNoticeModalProps> = ({
  isOpen,
  onClose,
  onUpgrade,
  customClasses = {},
}) => {

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);



  if (!isOpen) return null;

  return (
    <Modal 
    styles={{ content: { padding: "1rem" } }}
    opened={isOpen} title={"Trial is about to expire"} radius={"xl"} centered onClose={onClose}>
    <div className='flex flex-col gap-4'>
    <Text className={`text-sm text-gray-600`}>Upgrade your plan to unlock all features.</Text>
        
        <Button
          onClick={onUpgrade}
          radius={"lg"}
          className={`bg-primary hover:bg-blue-950 text-white disabled:bg-gray-300
            disabled:cursor-not-allowed ${customClasses.button || ''}`}
        >
         Upgrade Plan
      </Button>
    </div>

    </Modal>
  );
};

export default TrialExpiryNoticeModal;