import React from 'react';
import { useForm, yupResolver } from "@mantine/form";
import * as Yup from "yup";
import { Text } from "@mantine/core";
import InputField from "src/components/common/Inputs/InputField";
import { CustomButton } from "src/components/common/Buttons/CustomButton";
import { SplitCodeInput } from 'src/components/common/Inputs/SplitCodeInput';
import { AuthHeader } from 'src/components/Cards/AuthHeaderCard';

const schema = Yup.object().shape({
  code: Yup.string().required("Verification code is required").length(5, "Code must be 6 digits"),
});

interface VerifyCodeProps {
  onSubmit: (code: string) => void;
  onResend: () => void;
  loading: boolean;
  error?: string;
  email: string;
}

export const VerifyCode: React.FC<VerifyCodeProps> = ({ onSubmit, onResend, loading, error, email }) => {
  const form = useForm({
    initialValues: { code: "" },
    validate: yupResolver(schema),
    validateInputOnBlur: true,
    validateInputOnChange: true,
  });

  const handleCodeChange = (code: string) => {
    form.setFieldValue('code', code);
  };

  return (
    <form onSubmit={form.onSubmit((values) => onSubmit(values.code))}>
      <div className= 'space-y-6'>
      <AuthHeader title='Password reset' subtitle={`We sent a code to ${email}`} />
      <SplitCodeInput
        length={5}
        onChange={handleCodeChange}
      />
      <CustomButton type="submit" loading={loading} disabled={!form.isValid()} className="mt-4">
        Continue
      </CustomButton>
      <Text className="text-sm text-center mt-4">
        Didn't receive the code? <a onClick={onResend} className="text-primary font-semibold">Click to resend</a>
      </Text>
      {error && <Text color="red" className="mt-2">{error}</Text>}
      </div>

    </form>
  );
};