import { Button, Text } from "@mantine/core"
import { ReadOnlyChatHistoryTile } from "src/components/Chat/ReadOnlyHistoryTile"
import { AppButton } from "src/components/common/Buttons/AppButton"
import { FocusButton } from "src/components/common/Buttons/FocusButton"
import { IconButton } from "src/components/common/Buttons/IconButton"
import { ChatSession } from "src/interfaces/Chat"
import { FONT_SIZES } from "src/utils/styles"

interface ParentChildViewSection {
    chats: ChatSession[]
    selectSession: (id: string) => void
    selectedSession: string
}

export const ParentChildViewSection = (props: ParentChildViewSection) => {
    const { chats, selectedSession, selectSession } = props


    if (chats?.length > 0) {
        return (<>
            <Text fw={600} style={{ fontSize: FONT_SIZES.h1 }}>Chat History</Text>
            <div className="overflow-y-auto no-scrollbar flex-grow">
                {chats.map((item, index) => (
                    <ReadOnlyChatHistoryTile onPress={selectSession} selected={item.id == selectedSession} item={item} key={index} />
                ))}
                
            </div>
        </>)
    } else {
        return (<></>)
    }
}