import { Center, Text } from "@mantine/core"
import { colors } from "@mui/material"

interface Disclaimer{
    text: string
}

export const Disclaimer = (props: Disclaimer) => {
    const {text} = props

    return(<Center style={{ margin: "0.25rem" }}>
    <Text  className="text-xs text-gray-500 font-semibold text-center" >Disclaimer: {text}</Text>
  </Center>)
}