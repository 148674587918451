import React from 'react';
import { BackgroundImage, Image } from "@mantine/core";
import useResponsive from "src/hooks/useResponsive";

export const VisualSideLayout: React.FC = () => {
  const { isDesktop } = useResponsive();

  return (
    <div className={`${!isDesktop ? 'max-w-sm' : 'max-w-2xl'} w-full space-y-4`}>
      <div className="lg:flex justify-center hidden">
        <Image src="/logo.png" className={`w-full object-contain ${!isDesktop ? 'max-h-36' : 'max-w-lg'}`} />
      </div>
      {/* <div className="flex justify-center">
        <Image src="/headline.png" className={`w-full object-contain ${!isDesktop ? 'max-h-8' : 'max-w-sm'}`} />
      </div> */}
    </div>
  );
};